import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoriteListComponent } from './favorite-list.component';
import { FavoritesComponent } from '@wissenswerft/favorites';


@NgModule({
  declarations: [

    FavoriteListComponent,
    FavoritesComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FavoriteListComponent
  ]
})
export class FavoriteListModule { }
