import { Injectable } from '@angular/core';

export class Production {
    arg: string;

    serieOne: number;

    serieTwo: number;

    serieThree: number;
}

const productionData: Production[] = [{
    arg: 'categoryOne',
    serieOne: 4.21,
    serieTwo: 0.8,
    serieThree: 7.47,
}, {
    arg: 'categoryTwo',
    serieOne: 3.33,
    serieTwo: 1.06,
    serieThree: 5,
}, {
    arg: 'categoryThree',
    serieOne: 7.6,
    serieTwo: 0.78,
    serieThree: 1.71,
},
{
    arg: 'categoryFour',
    serieOne: 2.2,
    serieTwo: 0.52,
    serieThree: 2.39,
}, 
{
    arg: 'categoryFive',
    serieOne: 2.16,
    serieTwo: 3.09,
    serieThree: 6.26,
}, {
    arg: 'categorySix',
    serieOne: 2.16,
    serieTwo: 3.09,
    serieThree: 6.26,
}, {
    arg: 'categorySeven',
    serieOne: 2.16,
    serieTwo: 3.09,
    serieThree: 6.26,
}];

@Injectable({
    providedIn: 'root',
})
export class HomeService {
    getProductionData(): Production[] {
        return productionData;
    }
}