import { Injectable } from '@angular/core';
import {
    GeoDsCoreDataService,
    QueryColumn,
    QueryObjectsModel,
    Query
} from '@wissenswerft/core/data';
import { Audit } from '@wissenswerft/organizational-structure';
import { ObjectKeys } from '../../../services/data.service';

@Injectable({
    providedIn: 'root',
})
export class AuditService {

    constructor(
        private coreDataService: GeoDsCoreDataService
    ) { }

    getAudit(): Audit {
        const audit = new Audit();
        return audit;
    }
    public prepareQueryToGetAudits(companyId?): QueryObjectsModel {
        const mainQuery: Query = new Query();
        const mainQueryColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('AuditDate', 'AuditDate'),
            this.coreDataService.createQueryColumn('Designation', 'Designation'),
            this.coreDataService.createQueryColumn('Percentage', 'Percentage'),
            this.coreDataService.createQueryColumn(
                "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
                'IsFavorite'
            ),
            this.coreDataService.createQueryColumn(
                "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
                'BoxDetailId'
            )
        ];
        const otherColumns = [
            this.coreDataService.createQueryColumn('IdRefLibrary', 'LibraryId'),
            this.coreDataService.createQueryColumn('ref:ParentId', 'Company'),
            this.coreDataService.createQueryColumn('Score', 'Score'),
            this.coreDataService.createQueryColumn('MaxScore', 'MaxScore'),
            this.coreDataService.createQueryColumn('ParentId', 'CompanyId')
        ];
        if (!companyId) {
            mainQueryColumns.push(...otherColumns);
        }
        mainQuery.ObjectType = ObjectKeys.AUDIT;
        mainQuery.Columns = mainQueryColumns;
        if (companyId) {
            mainQuery.OPath = `ParentId='${companyId}'`;
        }
        const queryDocument: QueryObjectsModel = new QueryObjectsModel();
        queryDocument.ObjectQueries = [mainQuery];

        return queryDocument;
    }
}
