import { Injectable } from '@angular/core';
import { GeoDsCoreDataService, Query, QueryColumn, QueryColumnSortOrder, QueryObjectsModel, QueryParameter } from '@wissenswerft/core/data';
import { Observable } from 'rxjs';
import { ObjectKeys } from '../../services/data.service';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    
    constructor(private coreDataService: GeoDsCoreDataService) { }

    public getAuditsByCategory(companyId: string): Observable<ArrayBuffer> {
        const query = this.prepareAuditsByCategoryQuery(companyId);
        return this.coreDataService.executeReadObjectsQuery(query);
    }

    private prepareAuditsByCategoryQuery(companyId: string): QueryObjectsModel {
        const addressQuery: Query = new Query();
        addressQuery.ObjectType = 'Address';
        addressQuery.OPath = "Id = $CompanyId And AllAudits[ParentId = ^.Id].AuditItems[ParentId = ^.Id].Type = 'Category'";

        const addressQueryColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('Name', 'Name')
        ];
        addressQuery.Columns = addressQueryColumns;

        const auditsQuery: Query = new Query();
        auditsQuery.Name = 'AllAudits';
        auditsQuery.OPath = "AllAudits";

        const auditsQueryColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('AuditDate', 'AuditDate'),
            this.coreDataService.createQueryColumn('MaxScore', 'MaxScore'),
            this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
            this.coreDataService.createQueryColumn('Score', 'Score')
        ];
        auditsQuery.Columns = auditsQueryColumns;

        const auditItemsQuery: Query = new Query();
        auditItemsQuery.Name = 'AuditItems';
        auditItemsQuery.OPath = "AuditItems[Type = 'Category']";

        const auditItemsQueryColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('Designation', 'Designation'),
            this.coreDataService.createQueryColumn('MaxScore', 'MaxScore'),
            this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
            this.coreDataService.createQueryColumn('Percentage', 'Score'),
            this.coreDataService.createQueryColumn('Type', 'Type'),
            this.coreDataService.createQueryColumn('AudAudit.AuditDate', 'AuditDate')
        ];
        auditItemsQuery.Columns = auditItemsQueryColumns;

        auditsQuery.ObjectQueries = [auditItemsQuery];
        addressQuery.ObjectQueries = [auditsQuery];

        const addressQueryDocument: QueryObjectsModel = new QueryObjectsModel();
        const queryParams: QueryParameter = {
            Name: 'CompanyId',
            Value: <any>companyId
        }
        addressQueryDocument.Parameters = [queryParams];
        addressQueryDocument.ObjectQueries = [addressQuery];
        return addressQueryDocument
    }

    public prepareQueryToGetAuditSelected(selectedAuditIdFromGrid: string): QueryObjectsModel {
        const mainQuery: Query = new Query();
        const mainQueryColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('ref:ParentId', 'Company'),
            this.coreDataService.createQueryColumn('Score', 'Score'),
            this.coreDataService.createQueryColumn('Percentage', 'Percentage'),
            this.coreDataService.createQueryColumn('Designation', 'Designation'),
            this.coreDataService.createQueryColumn('AuditDate', 'AuditDate'),
            this.coreDataService.createQueryColumn('MaxScore', 'MaxScore')
        ];
        mainQuery.ObjectType = ObjectKeys.AUDIT;
        mainQuery.Columns = mainQueryColumns;
        mainQuery.OPath = "Id='" + selectedAuditIdFromGrid + "'";

        const queryDocument: QueryObjectsModel = new QueryObjectsModel();
        queryDocument.ObjectQueries = [mainQuery];

        return queryDocument;
    }


    public prepareQueryToGetAudits(companyId: string): QueryObjectsModel {
        const mainQuery: Query = new Query();
        const mainQueryColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('ref:ParentId', 'Company'),
            this.coreDataService.createQueryColumn('Score', 'Score'),
            this.coreDataService.createQueryColumn('Percentage', 'Percentage'),
            this.coreDataService.createQueryColumn('Designation', 'Designation'),
            this.coreDataService.createQueryColumn('AuditDate', 'AuditDate', QueryColumnSortOrder.Ascending),
            this.coreDataService.createQueryColumn('MaxScore', 'MaxScore'),
            this.coreDataService.createQueryColumn(
                "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
                'IsFavorite'
            ),
            this.coreDataService.createQueryColumn(
                "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
                'BoxDetailId'
            )
        ];
        mainQuery.ObjectType = ObjectKeys.AUDIT;
        mainQuery.Columns = mainQueryColumns;
        mainQuery.OPath = "ParentId='" + companyId + "'";
        const queryDocument: QueryObjectsModel = new QueryObjectsModel();
        queryDocument.ObjectQueries = [mainQuery];

        return queryDocument;
    }


}
