import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QueryColumn, QueryObjectsModel, Query, GUID, TargetColumnValue, PersistMode, TargetObjectData, ObjectKey, GeoDsPersistenceService, PersistObjectModel } from '@wissenswerft/core/data';
import { GeoDsCoreDataService } from "@wissenswerft/core/data";
import { Observable, of, Subject, Subscription } from 'rxjs';
import { DataService, ObjectKeys } from "../../services/data.service";
import { Company, SharedDataService } from "@wissenswerft/organizational-structure";
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FavoritesService } from 'libs/favorites/src/lib/favorites.service';
import { DxPopupComponent, DxScrollViewComponent, DxSelectBoxComponent, DxTextBoxComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { AppService } from '../../services/app.service';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { ObjectTypeNames } from '../favorite-list/favorite-list.component';
@Component({
  selector: 'digitalization-tool-customer-history',
  templateUrl: './customer-history.component.html',
  styleUrls: ['./customer-history.component.scss']
})
export class CustomerHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filterSelectBox') filterSelectBox: DxSelectBoxComponent;
  @ViewChild("addCompanyPopup") addCompanyPopup: DxPopupComponent;
  @ViewChild("deleteCompanyPopup") deleteCompanyPopup: DxPopupComponent;
  @ViewChild("companyGrid") companyGrid: GridComponent;
  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  @ViewChild('emailTextBox') emailTextBox: DxTextBoxComponent;
  @ViewChild('phoneTextBox') phoneTextBox: DxTextBoxComponent;
  @ViewChild('faxTextBox') faxTextBox: DxTextBoxComponent;

  private subscriptions: Subscription[] = [];
  public companies: Company[];
  public clonedCompanies: Company[];
  public columns: QueryColumn[] = [];
  public company: Company;
  public rowIndex: number;
  public buttonAction = '';
  public emptyCompany = false;
  public companytitles = [];
  public filterActive = false;
  private comapnyNumber = 100;
  public showLoader = true;
  public items = [];
  public displayGrid = false;
  public oldValueOfName = '';
  public oldValueOfMatchCode = '';
  public oldValueOfOrganization = '';
  public oldValueOfStreet = '';
  public oldValueOfTown = '';
  public oldValueOfLand;
  public oldValueOfEmail = '';
  public oldValueOfStatus;
  public oldValueOfFax;
  public oldValueOfPhone;
  public oldValueOfZip;
  public oldValueOfHouseNumber;
  public oldValueOfCustomerNumber;
  public isUpdate = false;
  public selectedCompanyId: string;
  public searchTerm = new Subject<string>();
  public lastcompanyWithAudit$: Observable<any>;
  public isFavorite = false;

  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-Name'),
      dataField: "name",
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-Organization'),
      dataField: 'companyTitle',
      visibleIndex: 3,
      lookup: {
        dataSource:
          this.dataService.cachedCompaniesTitles,
        displayExpr: 'Description',
        valueExpr: 'Value'
      }
    },
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-Match-Code'),
      dataField: "matchCode",
      visibleIndex: 1
    },
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-Status'),
      dataField: 'status',
      visibleIndex: 4,
      lookup: {
        dataSource:
          this.dataService.cachedStatus,
        displayExpr: 'Description',
        valueExpr: 'Value'
      }
    },
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-Customer-Number'),
      dataField: "customerNumber",
      visibleIndex: 9,
      visible: false
    },
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-Street'),
      dataField: "street",
      visibleIndex: 6
    },
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-City'),
      dataField: "city",
      visibleIndex: 5
    },
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-Zip'),
      dataField: "zip",
      visibleIndex: 8
    },
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-Phone'),
      dataField: "phone",
      visibleIndex: 7,
      visible: false
    },
    {
      caption: this.dataService.res('Dt-Company-Columns-Header-Email'),
      dataField: "eMail",
      visibleIndex: 2,
      visible: false
    }
  ];

  public createButtonOptions = {
    text: this.dataService.res('Dt-Create'),
    useSubmitBehavior: true
  };

  public editButtonOptions = {
    text: this.dataService.res('Dt-Edit'),
    useSubmitBehavior: true
  };

  public CancelButtonOptions = {
    text: this.dataService.res('Dt-Cancel'),
    onClick: () => this.onClosePopup()
  };
  constructor(
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    public dataService: DataService,
    public favoritesService: FavoritesService,
    private router: Router,
    private sharedDataService: SharedDataService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.company = new Company(null);
    this.columnsHeader.push({
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [{
        icon: "edit",
        text: this.dataService.res('Dt-Edit'),
        onClick: (e) => { this.openCompanyDetail(e); }
      }, {
        icon: "folder",
        text: this.dataService.res('Dt-Audit-Audits'),
        onClick: (e) => { this.redirectAudits(e); }
      }, {
        icon: 'trash',
        text: this.dataService.res('Dt-Delete'),
        onClick: (e) => {
          this.openDeleteCompanyDialog(e);
        }
      },
      {
        icon: 'like',
        cssClass: 'favorite-inactive',
        text: 'add favorite',
        visible: (e) => !this.isFavoriteVisible(e),
        onClick: (e) => {
          this.addFavorite(e);
        }
      },
      {
        icon: 'like',
        cssClass: 'favorite-active',
        text: 'remove favorite',
        visible: (e) => this.isFavoriteVisible(e),
        onClick: (e) => {
          this.deleteFavorite(e);
        }
      }]
    });
    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((company: Company) => {
      this.companies.push(company);
    }));

    this.prepareQueryToGetCompanies();
    this.search(this.searchTerm).subscribe((results) => {
      this.companies = [];
      this.emptyCompany = false;
      if (results !== null) {
        this.filterActive = true;
        if (results.length === 0) {
          this.emptyCompany = true;
        } else {
          this.companies = results;
        }
      } else {
        this.filterActive = false;
        this.comapnyNumber = 0;
        this.companies = this.clonedCompanies.slice(0, this.comapnyNumber + 100);
      }
    });
  }


  public ngAfterViewInit(): void {
    this.filterSelectBox.value = this.filterArray[0].id;
  }

  public filterArray = [
    { id: 0, name: this.dataService.res('Dt-Home-filter-all') },
    { id: 1, name: this.dataService.res('Dt-Home-filter-Company-with-audits') },
    { id: 2, name: this.dataService.res('Dt-Home-filter-last-used-company') },

  ];
  public search(terms: Observable<string>) {
    return terms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) => this.searchEntries(term))
    );
  }

  public searchEntries(term: string) {
    if (!term) return of(null);
    term = term.toUpperCase();
    return of(
      this.items.filter((a) => a.searchProperty.indexOf(term) >= 0)
    );
  }

  public prepareQueryToGetCompanies() {
    this.columns.push(
      this.coreDataService.createQueryColumn('name', 'name'),
      this.coreDataService.createQueryColumn('matchCode', 'matchCode'),
      this.coreDataService.createQueryColumn('status', 'status'),
      this.coreDataService.createQueryColumn('id', 'Id'),
      this.coreDataService.createQueryColumn('companyTitle', 'companyTitle'),
      this.coreDataService.createQueryColumn('phone', 'phone'),
      this.coreDataService.createQueryColumn('eMail', 'eMail'),
      this.coreDataService.createQueryColumn('street', 'street'),
      this.coreDataService.createQueryColumn('houseNumber', 'houseNumber'),
      this.coreDataService.createQueryColumn('zip', 'zip'),
      this.coreDataService.createQueryColumn('country', 'country'),
      this.coreDataService.createQueryColumn('city', 'city'),
      this.coreDataService.createQueryColumn(
        "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
        'IsFavorite'
      ),
      this.coreDataService.createQueryColumn(
        "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
        'BoxDetailId'
      )
    );
    this.subscriptions.push(this.dataService.readObjects(ObjectKeys.ADDRESS, this.columns).subscribe(companiesData => {
      this.companies = companiesData.slice(0, this.comapnyNumber);
      this.clonedCompanies = [...companiesData];
      this.items = this.clonedCompanies.map((company) => {
        return {
          ...company,
          searchProperty: company.name.toUpperCase(),
        };
      });
      this.showLoader = false;
    }, error => {
      console.error(error);
      this.showLoader = false;
    }));
  }

  public getCompanyWithAudits() {
    const companyWithAudits = this.prepareQueryToGetCompanyWithAudits();
    this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(companyWithAudits).subscribe(companiesData => {
      this.companies = companiesData.slice(0, this.comapnyNumber);
      this.clonedCompanies = [...companiesData];
    }));
  }
  public getLastCompanyWithAudit() {
    this.lastcompanyWithAudit$ = (this.getCompanyData(localStorage.getItem('companyId')));
    this.subscriptions.push(this.lastcompanyWithAudit$.subscribe(companyData => {
      this.companies = companyData.slice(0, this.comapnyNumber);
      this.clonedCompanies = [...companyData];
    }))
  }
  public prepareQueryToGetCompanyWithAudits(): QueryObjectsModel {
    const query: Query = new Query();
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('id', 'Id'),
      this.coreDataService.createQueryColumn('name', 'name'),
      this.coreDataService.createQueryColumn('companyTitle', 'companyTitle'),
      this.coreDataService.createQueryColumn('phone', 'phone'),
      this.coreDataService.createQueryColumn('eMail', 'eMail'),
      this.coreDataService.createQueryColumn('street', 'street'),
      this.coreDataService.createQueryColumn('houseNumber', 'houseNumber'),
      this.coreDataService.createQueryColumn('zip', 'zip'),
      this.coreDataService.createQueryColumn('country', 'country'),
      this.coreDataService.createQueryColumn('city', 'city'),
      this.coreDataService.createQueryColumn(
        "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
        'IsFavorite'
      ),
      this.coreDataService.createQueryColumn(
        "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
        'BoxDetailId'
      )
    ];
    query.ObjectType = ObjectKeys.ADDRESS;
    query.Columns = queryColumns;
    query.OPath = 'Count(AllAudits) > 0';
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [query];

    return queryDocument;
  }

  public itemClickSelect(e) {
    let filterAction = e.itemData.id;
    if (filterAction === 0) {
      this.prepareQueryToGetCompanies();
    } else if (filterAction === 1) {
      this.getCompanyWithAudits();
    } else {
      this.getLastCompanyWithAudit();
    }
  }
  public prepareQueryToGetCompanyId(): Observable<any> {
    const query: Query = new Query();
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('ParentId', 'Id')
    ];
    query.ObjectType = 'AudAudit';
    query.Columns = queryColumns;
    const parentId = localStorage.getItem('companyId');
    query.OPath = "(ParentId='" + parentId + "')";
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [query];

    return this.coreDataService.executeReadObjectsQuery(queryDocument);
  }

  public getCompanyData(companyId): Observable<any> {
    const query: Query = new Query();
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('id', 'Id'),
      this.coreDataService.createQueryColumn('name', 'name'),
      this.coreDataService.createQueryColumn('companyTitle', 'companyTitle'),
      this.coreDataService.createQueryColumn('phone', 'phone'),
      this.coreDataService.createQueryColumn('eMail', 'eMail'),
      this.coreDataService.createQueryColumn('street', 'street'),
      this.coreDataService.createQueryColumn('houseNumber', 'houseNumber'),
      this.coreDataService.createQueryColumn('zip', 'zip'),
      this.coreDataService.createQueryColumn('country', 'country'),
      this.coreDataService.createQueryColumn('city', 'city'),
      this.coreDataService.createQueryColumn(
        "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
        'IsFavorite'
      ),
      this.coreDataService.createQueryColumn(
        "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
        'BoxDetailId'
      )
    ];
    query.ObjectType = 'Address';
    query.Columns = queryColumns;
    query.OPath = `Id='${companyId}'`;
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [query];

    return this.coreDataService.executeReadObjectsQuery(queryDocument);
  }
  public isFavoriteVisible(e) {
    if (e.row?.data.IsFavorite === 1) {
      return true;
    } else {
      return false;
    }
  }

  public addFavorite(e): void {
    this.favoritesService.persistFavoriten(GUID.newGUID(), ObjectTypeNames.Company, e.row?.data.Id, e.row?.data.name).subscribe((data) => {
      this.companiesUpdateState(e, 'add', data);
    });
  }

  public deleteFavorite(e): void {
    this.favoritesService.deleteFavoriten(e.row?.data.BoxDetailId);
    this.companiesUpdateState(e, 'delete');
  }

  public companiesUpdateState(e, state: string, favoriteData?): void {
    let selectedIndex = this.companies.findIndex((company) => company.Id === e.row?.data.Id);
    if (state === 'add') {
      const clonedItem = { ...e.row?.data, IsFavorite: 1, BoxDetailId: favoriteData.Id };
      this.companies[selectedIndex] = clonedItem;
    } else {
      const clonedItem = { ...e.row?.data, IsFavorite: 0 };
      this.companies[selectedIndex] = clonedItem;
    }
  }

  public openCompanyDialog(isUpdate?: boolean): void {
    this.isUpdate = isUpdate;
    this.oldValueOfCustomerNumber = this.company.customerNumber;
    this.oldValueOfEmail = this.company.eMail;
    this.oldValueOfFax = this.company.fax;
    this.oldValueOfStatus = this.company.status;
    this.oldValueOfHouseNumber = this.company.houseNumber;
    this.oldValueOfLand = this.company.country;
    this.oldValueOfMatchCode = this.company.matchCode;
    this.oldValueOfName = this.company.name;
    this.oldValueOfOrganization = this.company.companyTitle;
    this.oldValueOfPhone = this.company.phone;
    this.oldValueOfStreet = this.company.street;
    this.oldValueOfTown = this.company.city;
    this.oldValueOfZip = this.company.zip;
    this.addCompanyPopup.instance.show();
  }

  public onClosePopup(persist?: boolean): void {
    if (!persist) {
      if (this.isUpdate) {
        this.company.customerNumber = this.oldValueOfCustomerNumber;
        this.company.eMail = this.oldValueOfEmail;
        this.company.fax = this.oldValueOfFax;
        this.company.status = this.oldValueOfStatus;
        this.company.houseNumber = this.oldValueOfHouseNumber;
        this.company.country = this.oldValueOfLand;
        this.company.matchCode = this.oldValueOfMatchCode;
        this.company.name = this.oldValueOfName;
        this.company.companyTitle = this.oldValueOfOrganization;
        this.company.phone = this.oldValueOfPhone;
        this.company.street = this.oldValueOfStreet;
        this.company.city = this.oldValueOfTown;
        this.company.zip = this.oldValueOfZip;
        this.isUpdate = false;
      }
    }
    this.addCompanyPopup.instance.hide();
    this.deleteCompanyPopup.instance.hide();
    this.emptyData();
  }

  public openCompanyDetail(event): void {
    this.buttonAction = event.column.name;
    this.company = event.row?.data;
    this.rowIndex = event.row.dataIndex;
    this.openCompanyDialog(true);
  }

  public openDeleteCompanyDialog(event): void {
    this.selectedCompanyId = event.row?.data.Id;
    this.deleteCompanyPopup.instance.show();
  }

  public formatContactNumber(value: string) {
    if (value) {
      return "+" + value.substring(0, 2) + "(" + value.substring(2, 6) + ")" + value.substring(6, 11) + "-" + value.substring(11, 13);
    } else {
      return null;
    }
  }

  public getLibraryById(columns: QueryColumn[], id) {
    let opath = 'Id=' + "'" + id + "'";
    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.ADDRESS, columns, opath)
        .subscribe((companyData) => {
          if (this.buttonAction == 'edit') {
            let selectedIndex = this.companies.findIndex((company) => company.Id === id);
            const clonedItem = { ...this.company };
            this.companies[selectedIndex] = clonedItem;
            this.appService.callNotification({
              message: this.dataService.res('Dt-Company-Succees-update'),
              type: ToastType.SUCCESS
            });
          } else {
            this.clonedCompanies.push(companyData[0]);
            this.companyGrid.refreshGrid();
            this.appService.callNotification({
              message: this.dataService.res('Dt-Company-Succees-insert'),
              type: ToastType.SUCCESS
            });
          }
          this.onClosePopup(true);
        })
    );
  }
  public validTextBoxStatus(): boolean {
    if ((this.emailTextBox.validationStatus !== 'invalid') && (this.phoneTextBox.validationStatus !== 'invalid') && (this.faxTextBox.validationStatus !== 'invalid')) {
      return true;
    } else return false;
  }
  public persistCompany() {
    const companyPersistQuery: TargetObjectData = new TargetObjectData();
    companyPersistQuery.ObjectKey = new ObjectKey();
    companyPersistQuery.ObjectKey.ObjectType = ObjectKeys.ADDRESS;
    if (this.buttonAction === 'edit') {
      companyPersistQuery.Mode = PersistMode.Update;
      companyPersistQuery.ObjectKey.Id = this.company.Id;
    } else {
      companyPersistQuery.Mode = PersistMode.Insert;
    }

    if (this.validTextBoxStatus()) {
      const companyColumns: TargetColumnValue[] = [
        { Name: 'Name', Value: this.company.name },
        { Name: 'Status', Value: this.company.status },
        { Name: 'CustomerNumber', Value: this.company.customerNumber },
        { Name: 'Country', Value: <string>this.company.country },
        { Name: 'City', Value: this.company.city },
        { Name: 'Phone', Value: this.formatContactNumber(this.company.phone) },
        { Name: 'Fax', Value: this.formatContactNumber(this.company.fax) },
        { Name: 'EMail', Value: this.company.eMail },
        { Name: 'Street', Value: this.company.street },
        { Name: 'MatchCode', Value: this.company.matchCode },
        { Name: 'HouseNumber', Value: this.company.houseNumber },
        { Name: 'Zip', Value: this.company.zip },
        { Name: 'CompanyTitle', Value: this.company.companyTitle }
      ];
      companyPersistQuery.TargetColumns = companyColumns;
      const persistObject: PersistObjectModel = new PersistObjectModel();
      persistObject.Object = companyPersistQuery;
      this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((data) => {
        this.getLibraryById(this.columns, data.Id);
      }, () => {
        this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });
      })
      );
    }

  }

  public deleteCompany(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.ADDRESS, this.selectedCompanyId)
        .subscribe((deletedCompany) => {
          if (deletedCompany?.Id) {
            this.companies = this.companies.filter(
              (company) => company.Id != deletedCompany?.Id
            );
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Delete-Company-Success'),
              type: ToastType.SUCCESS
            });
          } else {
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Error'),
              type: ToastType.ERROR
            });
          }
        }, error => {
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Error'),
            type: ToastType.ERROR
          });
        }
        )
    );
    this.deleteCompanyPopup.instance.hide();
  }

  public onCreateCompany(event): void {
    event.preventDefault();
    this.persistCompany();
  }

  public onHiding(): void {
    this.emptyData();
  }

  public emptyData(): void {
    this.company = new Company(null);
    this.buttonAction = '';
  }

  public redirectAudits(event): void {
    const companyName = event.row?.data.name;
    const companyId = event?.row?.data.Id;

    if (companyName && companyId) {
      this.dataService.prepareCompanyData(companyId, companyName);
      const state = JSON.parse(localStorage.getItem('auditGrid'));
      state.filterValue = ["Company", "contains", companyName];
      localStorage.setItem("auditGrid", JSON.stringify(state));
      localStorage.setItem('companyId', companyId);
      localStorage.setItem('companyName', companyName);
      this.router.navigate(['audits'], { state: { companyName: companyName, companyId: companyId } });
    }
  }

  public returnDxItemCssClass(className: string): string {
    return className;
  }

  public changedisplay(value: boolean): void {
    this.displayGrid = value;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}