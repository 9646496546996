import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BibCategory } from '@wissenswerft/organizational-structure';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DataService, ObjectKeys } from '../../services/data.service';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

import { EvaluationService } from './evaluation.service';
import { Location } from '@angular/common';
import { QueryObjectsModel, Query, QueryColumn, GeoDsCoreDataService } from '@wissenswerft/core/data';
import { AppService } from '../../services/app.service';
import { ToastType } from '@wissenswerft/ww-library';
import { Column } from 'devextreme/ui/data_grid';


interface gridRow {
  id?: string;
  question: string;
  answer: string; //SelectedResponse[0]
  category: string;
  score: string;
  maxScore: string;
  percentage: string;
}

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public companyName = '';
  public auditId = '';
  public auditName = '';
  public categories: BibCategory[] = [];
  public questions: gridRow[] = [];
  public auditItems: any[] = [];
  public percentageSize = 0;
  public modeBar: ProgressBarMode = 'determinate';
  public modeSpinner: ProgressSpinnerMode = 'determinate';
  public score = 0;
  public maxScore = 0;
  public percentage = 0;
  public showLoader = true;
  public categoryIcon = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M101,56.24V30.84L79,18.14l-19,11-19-11L19,30.84v25.4l19,11V89.14l22,12.72L82,89.14V67.23ZM38,60.31,25,52.78v-15l13,7.5Zm-10-27.75,13-7.49,13,7.51-13,7.5Zm29,5.22v15l-13,7.5v-15Zm0,55.43L44,85.68v-15l13,7.51Zm-10-27.74L60,58l13,7.48L60,73ZM63,37.78l13,7.49v15l-13-7.5Zm0,55.43v-15l13-7.51v15Zm3-60.63,13-7.51,13,7.49L79,40.08ZM82,45.27l13-7.5v15L82,60.31Z"/></svg>'
  public displayGrid = false;
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Dt-TreeList-Category'),
      dataField: 'category',
      groupIndex: 0,
      visibleIndex: 1,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Dt-TreeList-Question'),
      dataField: 'question',
      visibleIndex: 1,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Dt-TreeList-Answer'),
      dataField: 'answer',
      visibleIndex: 1,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-Score'),
      dataField: 'score',
      visibleIndex: 3,
      dataType: 'number',
      alignment: 'left'
    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-MaxScore'),
      dataField: 'maxScore',
      visibleIndex: 4,
      dataType: 'number',
      alignment: 'left'
    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-Percentage'),
      dataField: 'percentage',
      visibleIndex: 2,
      dataType: 'number',
      alignment: 'left',
      customizeText: text => { return text.value + ' %' }
    },
    {
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [
        {
          icon: 'parentfolder',
          text: this.dataService.res('Dt-Edit'),
          onClick: (e) => {
            this.redirectQuestions(e.row?.data.category, e.row?.data.question)
          }
        },
        {
          icon: 'textdocument',
          text: this.dataService.res('Dt-Audit-Report'),
          onClick: (e) => { }
        }
      ]
    }
  ];
  constructor(
    private evaluationService: EvaluationService,
    private router: Router,
    public dataService: DataService,
    private _location: Location,
    private coreDataService: GeoDsCoreDataService,
    private appService: AppService,

  ) {
  }

  public moveBackToAudit(): void {
    this._location.back();
  }

  public countAnswered(Questions): number {
    return Questions?.reduce((sum, value) => (value.hasOwnProperty('SelectedResponse') ? sum + 1 : sum), 0)
  }

  ngOnInit(): void {
    this.showLoader = true;
    this.auditName = localStorage.getItem('auditDesignation');
    this.companyName = localStorage.getItem('auditCompanyName');
    this.auditId = localStorage.getItem('addedAuditId');
    this.subscriptions.push(this.dataService.favoriteName$.subscribe((data) => {
      let favoriteData = [];
      favoriteData = Array.from(this.dataService.favoriteData.get(data));
      this.dataService.favoriteData.get(data)
      this.auditName = favoriteData[0].Designation;
      this.companyName = favoriteData[0].Company;
      this.auditId = favoriteData[0].Id;
      const categoryQuery = this.evaluationService.prepareQueryToGetAuditItemsCategories();
      const auditQuery = this.prepareQueryToGetAudit();
      this.getcategoriesData(categoryQuery, auditQuery);
    }))
    const categoryQuery = this.evaluationService.prepareQueryToGetAuditItemsCategories();
    const auditQuery = this.prepareQueryToGetAudit();
    this.getcategoriesData(categoryQuery, auditQuery)
  }

  public getcategoriesData(categoryQuery: Observable<any>, auditQuery: QueryObjectsModel): void {
    const categoryData = forkJoin([
      categoryQuery,
      this.coreDataService.executeReadObjectsQuery(auditQuery)
    ]);
    this.subscriptions.push(categoryData.subscribe((data) => {
      this.auditItems = data[0];
      const auditResponse = data[1][0];
      if (this.auditItems?.length) {
        this.evaluationService.categories = this.auditItems;
        this.categories = this.auditItems;
        this.auditItems.forEach((category, index: number) => {
          this.evaluationService.cachedCategoriesTitles[index] =
            category.Designation;
          this.evaluationService.cachedCategories.set(
            category.Designation,
            category
          );
        });
        this.auditItems.forEach((category, index: number) => {
          category.Questions.forEach((question) => {
            this.questions.push({
              id: category.Id, category: category.Designation, question: question.Designation,
              score: question.Score, maxScore: question.MaxScore, percentage: question.Percentage,
              answer: (question.SelectedResponse ? question.SelectedResponse[0]?.Designation : "-")
            })
          })
        })
      }
      this.score = auditResponse.Score === null ? 0 : auditResponse.Score;
      this.maxScore = auditResponse.MaxScore === null ? 0 : auditResponse.MaxScore;
      this.percentage = auditResponse.Percentage === null ? 0 : auditResponse.Percentage;
      this.showLoader = false;

    }, (error) => {
      console.error(error);
      this.appService.callNotification({
        message: this.dataService.res('Dt-Notification-Error'),
        type: ToastType.ERROR
      });
      this.showLoader = false;
    }));
  }
  public prepareQueryToGetAudit(): QueryObjectsModel {
    const query: Query = new Query();
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Score', 'Score'),
      this.coreDataService.createQueryColumn('MaxScore', 'MaxScore'),
      this.coreDataService.createQueryColumn('Percentage', 'Percentage')
    ];
    query.ObjectType = ObjectKeys.AUDIT;
    query.Columns = queryColumns;
    query.OPath = `Id='${this.auditId}'`;
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [query];

    return queryDocument;
  }

  public redirectQuestions(categoryDesination, selectedCategoryObject, questionDesignation?): void {
    localStorage.setItem('audit', this.auditName);
    localStorage.setItem('selectedCategory', categoryDesination);
    if (questionDesignation != null && questionDesignation != undefined) {
      localStorage.setItem('questionDesignation', questionDesignation);
    }
    this.evaluationService.selectedCategory = categoryDesination;
    this.evaluationService.selectedCategoryObject = selectedCategoryObject;
    this.router.navigate(['qcm']);
  }

  public getScorePercentage(category: BibCategory): number {
    let percentage = 0;
    if (category?.Score !== 0) {
      percentage = (category?.Score! / (category?.MaxScore !== null ? category.MaxScore : 200)) * 100;
    }
    else {
      percentage = 0;
    }
    percentage = Math.round(percentage);
    this.percentageSize = (percentage).toString().length;
    return (percentage < 0) ? 0 : percentage;
  }
  public getQuestionsPercentage(category: BibCategory): number {
    let percentage = (this.countAnswered(category?.Questions) / category?.Questions?.length) * 100;
    return percentage;
  }

  public changedisplay(value: boolean): void {
    this.displayGrid = value;
  }
  public navigateToResult(): void {
    this.router.navigate(['qcm', 'result']);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
