import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QueryColumn, QueryObjectsModel, Query, GeoDsCoreDataService, CompareHelper, ObjectHelper } from '@wissenswerft/core/data';
import { Audit, AuditItemTypes } from '@wissenswerft/organizational-structure';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxPolarChartComponent, DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Observable, Subscription } from 'rxjs';
import { DataService, ObjectKeys } from "../../services/data.service";
import { Router } from '@angular/router';
import { AppService } from '../../services/app.service';
import { concatMap, map, tap } from 'rxjs/operators';
import { DashboardService } from './dashboard.service';
import { AuditSelected, CompanyDetail, SpiderChartAxis } from './dashboard.model';
import { DashboardPopupComponent } from './dashboard-popup/dashboard-popup.component';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('dashboardGrid') dashboardGrid: GridComponent;
  @ViewChild('deleteAuditPopup') deleteAuditPopup: DxPopupComponent;
  @ViewChild('spiderChart') polarChart: DxPolarChartComponent;
  @ViewChild('zoomedPolarChart') zoomedPolarChart: DxPolarChartComponent;
  @ViewChild('zoomSpiderChart') zoomSpiderChart: DxPopupComponent;
  @ViewChild('zoomChart') zoomChart: DxPopupComponent;
  @ViewChild('dashboardPopup') dashboardPopup: DashboardPopupComponent;

  public chartData = [];
  public companyId = "";
  public companyName = "";
  public selectedAudit: Audit;
  public selectedAuditIdFromGrid: string;
  private subscriptions: Subscription[] = [];
  public audits: any;
  public selectedAuditId: string;
  public selectedAuditDate: string;
  public selectedCompanyId = '';
  public auditDate = '';
  public company = '';
  public designation = '';
  public maxScore: number;
  public percentage: number;
  public score: number;
  public auditSelecteData: Array<AuditSelected> = [];
  public listOfCategory = [];
  public auditItemsByDate = {};
  public spiderChartOptions: SpiderChartAxis[] = [];
  public isOpenCreateAudit = false;
  public isFilteredSpiderChart = true;
  public rowsSelected = false;
  private spiderChartFilteredData = [];
  public showLoader = true;
  private _audits = {};
  private repaintRep = 0;
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Dt-Audit-Designation'),
      dataField: 'Designation',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-Percentage'),
      dataField: 'Percentage',
      customizeText: text => { return text.value + ' %' },
      dataType: 'number',
      alignment: 'left',
      visibleIndex: 1
    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-AuditDate'),
      dataField: 'AuditDate',
      dataType: 'date',
      visibleIndex: 2,
      format: (date) => this.DateFormatter(date)
    },
  ];

  constructor(
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
    private router: Router,
    private appService: AppService,
    private dashboardService: DashboardService,
  ) { }

  public ngOnInit(): void {

    this.selectedCompanyId =
      this.dataService.companyData.size !== 0
        ? this.dataService.companyData.get('id')
        : localStorage.getItem('companyId');

    this.columnsHeader.push({
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [
        {
          icon: 'parentfolder',
          text: this.dataService.res('Dt-Edit'),
          onClick: (e) => {
            this.navigateToEvaluation(
              e.row?.data.Company,
              e.row?.data.Id,
              e.row?.data.LibraryId,
              e.row?.data.Designation
            );
          }
        },
        {
          icon: 'trash',
          text: this.dataService.res('Dt-Delete'),
          onClick: (e) => {
            this.openDeleteAuditDialog(e);
          }
        }
      ]
    });

    if (this.dataService.companyData.size !== 0) {
      this.companyId = this.dataService.companyData.get("id");
      this.companyName = this.dataService.companyData.get("companyName");

    } else {
      this.companyId = localStorage.getItem('companyId');
      this.companyName = localStorage.getItem("companyName");
    }
    const auditQuery = this.dashboardService.prepareQueryToGetAudits(this.companyId);
    this.prepareDashboardData(auditQuery);
    this.subscriptions.push(this.dataService.favoriteName$.subscribe((companyId) => {
      this.companyId = companyId;
      const auditQuery = this.dashboardService.prepareQueryToGetAudits(companyId);
      this.prepareDashboardData(auditQuery);
    }));
  }

  public abortSelect() {
    this.showLoader = false;
  }

  public companyIdSelectionOutput(companyDetail: CompanyDetail): void {
    this.companyId = companyDetail.id;

    const auditQuery = this.dashboardService.prepareQueryToGetAudits(this.companyId);
    this.prepareDashboardData(auditQuery);

    this.dashboardPopup.isVisible = false;
  }
  public DateFormatter(date: any): any {
    // dd/mm/yyyy
    return date.toLocaleDateString('en-GB');
  }

  public rowSelected(event): void {
    const selectedRows = event.selectedRowsData;

    if (selectedRows?.length === 1) {
      this.rowsSelected = false;
    } else {
      this.rowsSelected = true;
    }

    this.spiderChartFilteredData = [];
    selectedRows.forEach(selectedItem => {
      const searchKeyWord = "Score-" + selectedItem.AuditDate;
      this.isFilteredSpiderChart = false;
      this.chartData.forEach(graph => {
        if (Object.keys(graph)[1] === searchKeyWord)
          this.spiderChartFilteredData.push(graph);
      });
      this.selectedAuditIdFromGrid = selectedItem.Id;
    });
    this.polarChart.dataSource = this.spiderChartFilteredData;
    this.zoomedPolarChart.dataSource = this.spiderChartFilteredData;
    this.subscriptions.push(this.readAuditSelectedByCategories().subscribe());

    const auditSelectedQuery = this.dashboardService.prepareQueryToGetAuditSelected(this.selectedAuditIdFromGrid);
    this.subscriptions.push(
      this.coreDataService.executeReadObjectsQuery(auditSelectedQuery).subscribe((data) => {
        this.selectedAudit = data;
        this.auditDate = data[0].AuditDate;
        this.company = data[0].Company;
        this.designation = data[0].Designation;
        this.maxScore = data[0].MaxScore;
        this.percentage = data[0].Percentage;
        this.score = data[0].Score;
      })
    );
  }

  private prepareDashboardData(auditQuery: QueryObjectsModel): void {
    this.coreDataService.executeReadObjectsQuery(auditQuery).pipe(tap((data) => {
      if (!CompareHelper.isEmptyObject(data)) {
        data.forEach((audit) => {
          this._audits[audit.Id] = new Audit();
        });
      }
      this.resetDashBoardData();
      if (!CompareHelper.isEmptyObject(data)) {
        this.audits = data;
        this.auditDate = data[0].AuditDate;
        this.company = data[0].Company;
        this.designation = data[0].Designation;
        this.maxScore = data[0].MaxScore;
        this.percentage = data[0].Percentage;
        this.score = data[0].Score;
        this.selectedAuditIdFromGrid = data[0].Id;
        this.listOfCategory = [];
      }


    }),
      concatMap(() => this.readAuditSelectedByCategories()),
      concatMap(() => this.dashboardService.getAuditsByCategory(this.companyId))
    ).subscribe((company) => {
      const allAudits: Audit[] = [];
      company[0]?.AllAudits?.forEach((audit) => {
        allAudits.push(new Audit());
        audit.AuditItems?.forEach((auditItem) => {
          if (this.auditItemsByDate[auditItem.AuditDate]) {
            this.auditItemsByDate[auditItem.AuditDate].push(auditItem);
          } else {
            this.auditItemsByDate[auditItem.AuditDate] = [auditItem];
          }
        });
      });
      for (const [key, value] of Object.entries(this.auditItemsByDate)) {
        this.spiderChartOptions.push({
          name: key,
          valueField: 'Score-' + key,
          key: 'Designation'
        });
        for (const [auditItemKey, auditItem] of Object.entries(value)) {
          const elementToPush = {
            Designation: auditItem.Designation
          };
          elementToPush[`Score-${key}`] = auditItem.Score * 100;
          this.chartData.push({ ...elementToPush });
        }
      }
      this.polarChart.instance.option('dataSource', this.chartData);
      this.polarChart.instance.option('series', this.spiderChartOptions);
      this.showLoader = false;
    }, (error) => {
      console.error(error);
      this.showLoader = false;
    })
  }

  private resetDashBoardData(): void {
    this.chartData = [];
    this.spiderChartOptions = [];
    this.auditItemsByDate = [];
    this.audits = [];
    this.listOfCategory = [];
    this.score = 0;
    this.percentage = 0;
    this.maxScore = 0;
    this.auditDate = '';
  }

  public resetFilter(): void {
    this.isFilteredSpiderChart = true;
    this.polarChart.dataSource = this.chartData;
    this.zoomedPolarChart.dataSource = this.chartData;
    this.dashboardGrid?.selectAll();
  }

  public navigateToEvaluation(
    companyName: string,
    auditId: string,
    libraryId: string,
    auditDesignation: string
  ): void {
    localStorage.setItem('addedAuditId', auditId);
    localStorage.setItem('libraryId', libraryId);
    localStorage.setItem('auditDesignation', auditDesignation);
    localStorage.setItem('auditCompanyName', companyName);
    this.router.navigate(['evaluation'], {
      state: {
        auditId: auditId,
        companyName: companyName,
        libraryId: libraryId,
        auditDesignation: auditDesignation
      }
    });
  }

  public readAuditSelectedByCategories(): Observable<any> {
    const auditSelectedQuery: Query = new Query();
    const auditSelectedQueryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn(
        'Designation',
        'Designation'
      ),
      this.coreDataService.createQueryColumn(
        'ref:ParentId',
        'DesignationCategory'
      ),
      this.coreDataService.createQueryColumn('Score', 'Score')
    ];
    auditSelectedQuery.OPath = `ParentId='${this.selectedAuditIdFromGrid}' And Type='${AuditItemTypes.Category}'`;

    auditSelectedQuery.ObjectType = ObjectKeys.AUDITITEM;
    auditSelectedQuery.Columns = auditSelectedQueryColumns;

    const auditSelectedQueryDocument: QueryObjectsModel = new QueryObjectsModel();
    auditSelectedQueryDocument.ObjectQueries = [auditSelectedQuery];

    return this.coreDataService
      .executeReadObjectsQuery(auditSelectedQueryDocument)
      .pipe(map(categories => {
        this.auditSelecteData = categories;
        this.listOfCategory = []
        if (categories.length !== undefined) {
          categories?.forEach((category) => {
            const categoryToAdd = {
              arg: category.Designation,
              val: category.Score
            };
            this.listOfCategory.push(categoryToAdd);
            return categories;
          });
        }
      })
      );
  }

  public deleteAudit(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.AUDIT, this.selectedAuditId)
        .subscribe(
          (deletedAudit) => {
            if (deletedAudit?.Id) {

              this.audits = this.audits.filter(
                (audit) => audit.Id != deletedAudit?.Id,
              );
              this.chartData = this.chartData.filter((category) => !category.hasOwnProperty('Score-' + this.selectedAuditDate));
              this.polarChart.instance.refresh()
              this.appService.callNotification({
                message: this.dataService.res('Dt-Notification-Success'),
                type: ToastType.SUCCESS
              });
            }
          },
          (error) => {
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Error'),
              type: ToastType.ERROR
            });
          }
        )
    );
    this.onCloseDeletePopup();
  }

  public onCloseDeletePopup(): void {
    this.deleteAuditPopup.instance.hide();
  }

  public onCloseZoomChartPopup(): void {
    this.zoomSpiderChart.instance.hide();
    this.zoomChart.instance.hide();
  }

  public openDeleteAuditDialog(event): void {
    this.selectedAuditId = event.row.data.Id;
    this.selectedAuditDate = event.row.data.AuditDate
    this.deleteAuditPopup.instance.show();
  }

  public openAddAuditDialog(): void {
    this.isOpenCreateAudit = true;
  }

  public zoomChartDialog(): void {
    this.zoomChart.instance.show();
  }

  public zoomSpiderChartDialog(): void {
    this.repaintRep += 1;
    if (this.repaintRep > 1) {
      this.zoomSpiderChart.instance.show();
    }
    else {
      this.zoomSpiderChart.instance.show();
      this.zoomSpiderChart.instance.repaint();
    }
  }

  public dashboardPopUpDialog(event): void {
    this.dashboardPopup.isVisible = true;
  }

  public pointClickHandler(arg) {
    arg.target.select();
  }

  public close() {
    this.isOpenCreateAudit = false;
  }

  public valueWithPercent(arg): string {
    return `${arg.valueText}%`;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}