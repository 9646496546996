import { Component, OnInit, ViewChild } from '@angular/core';
import {
  GeoDsCoreDataService,
  GeoDsPersistenceService,
  GUID,
  ObjectKey,
  PersistMode,
  PersistObjectModel,
  PersistObjectsModel,
  Query,
  QueryColumn,
  QueryObjectsModel,
  TargetColumnValue,
  TargetObjectData
} from '@wissenswerft/core/data';
import { Library, SharedDataService } from '@wissenswerft/organizational-structure';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { FavoritesService } from 'libs/favorites/src/lib/favorites.service';
import { Observable, Subscription } from 'rxjs';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { ObjectTypeNames } from '../favorite-list/favorite-list.component';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'digitalization-tool-library-list',
  templateUrl: './library-list.component.html',
  styleUrls: ['./library-list.component.scss']
})
export class LibraryListComponent implements OnInit {
  @ViewChild('libraryGrid') libraryGrid: GridComponent;
  @ViewChild('addLibraryPopup') addLibraryPopup: DxPopupComponent;
  @ViewChild('deleteLibraryPopup') deleteLibraryPopup: DxPopupComponent;
  @ViewChild('form') form: DxFormComponent;

  public library: Library = new Library();
  public libraries: Library[];
  private subscriptions: Subscription[] = [];
  public buttonAction = '';
  public oldValueOfDesignation = '';
  public oldValueOfKind = '';
  public isUpdate = false;
  public rowIndex: number;
  public selectedLibraryId: string;
  public columns: QueryColumn[] = [];
  public isDeleteWarning = false;
  public showLoader = true;
  public assignCategorizingQuestions = false;
  private categorizingQuestionsId = "10A8445C-3D03-491C-9887-71EAB1157617";
  public libCategory;
  public editMode=false;
  public categoryQuestions;
  public generateLabrayItems$: Observable<any>;

  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Dt-Library-Columns-Header-Designation'),
      dataField: 'Designation',
      visibleIndex: 0,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Dt-Library-List-Kind'),
      dataField: 'Kind',
      visibleIndex: 1,
      dataType: 'string'
    },
  ];

  constructor(
    private persistenceService: GeoDsPersistenceService,
    private coreDataService: GeoDsCoreDataService,
    public dataService: DataService,
    private appService: AppService,
    private sharedDataService: SharedDataService,
    public favoritesService: FavoritesService
  ) { }

  ngOnInit(): void {
    let query = this.prepareQueryToGetQuestionsByCategory();
    this.coreDataService.executeReadObjectsQuery(query).subscribe(data => {
      this.libCategory = data[0];
      this.categoryQuestions = this.libCategory.BibQuestions;
    }
    );
    this.columnsHeader.push({
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [
        {
          icon: 'edit',
          text: this.dataService.res('Dt-Edit'),
          onClick: (e) => {
            this.openLibraryDetail(e);
          }
        },
        {
          icon: 'trash',
          text: this.dataService.res('Dt-Delete'),
          onClick: (e) => {
            this.openDeleteLibraryDialog(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-inactive',
          text: 'add favorite',
          visible: (e) => !this.isFavoriteVisible(e),
          onClick: (e) => {
            this.addFavorite(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-active',
          text: 'remove favorite',
          visible: (e) => this.isFavoriteVisible(e),
          onClick: (e) => {
            this.deleteFavorite(e);
          }
        }
      ]
    });

    this.columns.push(
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation'),
      this.coreDataService.createQueryColumn('Kind', 'Kind'),
      this.coreDataService.createQueryColumn(
        "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
        'IsFavorite'
      ),
      this.coreDataService.createQueryColumn(
        "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
        'BoxDetailId'
      )
    );

    this.getLibraries(this.columns);

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((library: Library) => {
      this.libraries.push(library);
    }));

  }

  public isFavoriteVisible(e) {
    if (e.row?.data.IsFavorite === 1) {
      return true;
    } else {
      return false;
    }
  }

  public addFavorite(e): void {
    this.favoritesService.persistFavoriten(GUID.newGUID(), ObjectTypeNames.AudLibrary, e.row?.data.Id, e.row?.data.Designation).subscribe((data) => {
      this.librariesUpdateState(e, 'add', data);
    });
  }

  public deleteFavorite(e): void {
    this.favoritesService.deleteFavoriten(e.row?.data.BoxDetailId);
    this.librariesUpdateState(e, 'delete');
  }

  public librariesUpdateState(e, state: string, favoriteData?): void {
    let selectedIndex = this.libraries.findIndex((library) => library.Id === e.row?.data.Id);
    if (state === 'add') {
      const clonedItem = { ...e.row?.data, IsFavorite: 1, BoxDetailId: favoriteData.Id };
      this.libraries[selectedIndex] = clonedItem;
    } else {
      const clonedItem = { ...e.row?.data, IsFavorite: 0 };
      this.libraries[selectedIndex] = clonedItem;
    }
  }

  public createButtonOptions = {
    text: this.dataService.res('Dt-Create'),
    useSubmitBehavior: true,
  };

  public editButtonOptions = {
    text: this.dataService.res('Dt-Edit'),
    useSubmitBehavior: true,
  };

  public cancelButtonOptions = {
    text: this.dataService.res('Dt-Cancel'),
    onClick: () => this.onClosePopup(),
  };

  public getLibraries(columns: QueryColumn[]) {
    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.LIBRARY, columns)
        .subscribe((librariesData) => {
          this.libraries = librariesData;
          this.subscriptions.push(this.dataService.favoriteName$.subscribe((data) => {
            if (this.dataService.favoriteData.get(data)?.length !== 0) {
              this.libraryGrid.dxDataGrid.instance.clearFilter();
              this.libraryGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', this.dataService.favoriteData.get(data));
            }
            else {
              this.libraryGrid.dxDataGrid.instance.clearFilter();
              this.libraryGrid.dxDataGrid.instance.refresh();
            }
          }))
          if (localStorage.getItem('libraryName')?.length == 0) {
            this.libraryGrid.dxDataGrid.instance.clearFilter();
            this.libraryGrid.dxDataGrid.instance.refresh();
          } else {
            this.libraryGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', localStorage.getItem('libraryName'));
          }
          this.showLoader = false;
        }, error => {
          console.error(error);
          this.showLoader = false;
        })
    );
  }

  public getLibraryById(columns: QueryColumn[], id) {
    let opath = 'Id=' + "'" + id + "'";
    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.LIBRARY, columns, opath)
        .subscribe((libraryData) => {
          if (this.buttonAction == 'edit') {
            let selectedIndex = this.libraries.findIndex((library) => library.Id === id);
            const clonedItem = { ...this.library, Designation: libraryData[0].Designation, Kind: libraryData[0].Kind };
            this.libraries[selectedIndex] = clonedItem;
            this.appService.callNotification({
              message: this.dataService.res('Dt-Library-Succees-update'),
              type: ToastType.SUCCESS
            });
          } else {
            this.sharedDataService.updateGridData(libraryData[0]);
            this.libraryGrid.refreshGrid();
            this.appService.callNotification({
              message: this.dataService.res('Dt-Library-Succees-insert'),
              type: ToastType.SUCCESS
            });
          }
          this.onClosePopup(true);
        })
    );
  }

  public persistLibrary() {
    const libraryPersistQuery: TargetObjectData = new TargetObjectData();
    libraryPersistQuery.ObjectKey = new ObjectKey();
    libraryPersistQuery.ObjectKey.ObjectType = ObjectKeys.LIBRARY;
    if (this.buttonAction === 'edit') {
      libraryPersistQuery.Mode = PersistMode.Update;
      libraryPersistQuery.ObjectKey.Id = this.library.Id;
    } else {
      libraryPersistQuery.Mode = PersistMode.Insert;
    }

    const categoryColumns: TargetColumnValue[] = [
      { Name: 'Designation', Value: this.library.Designation },
      { Name: 'Kind', Value: this.library.Kind }
    ];
    libraryPersistQuery.TargetColumns = categoryColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = libraryPersistQuery;
    return this.persistenceService.executePersistObjectQuery(persistObject);
  }

  public persistCategory(ParentId) {
    const categoryPersistQuery: TargetObjectData = new TargetObjectData();
    categoryPersistQuery.ObjectKey = new ObjectKey();
    categoryPersistQuery.ObjectKey.ObjectType = ObjectKeys.BIBCATEGORY;
    categoryPersistQuery.Mode = PersistMode.Insert;
    const categoryColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: ParentId },
      { Name: 'Designation', Value: this.libCategory.Designation },
      { Name: 'SortNr', Value: this.libCategory.SortNr },
    ];
    categoryPersistQuery.TargetColumns = categoryColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = categoryPersistQuery;
    return this.persistenceService
      .executePersistObjectQuery(persistObject);
  }

  public readAuditSelectedByLibraryId(): Observable<any> {
    const auditSelectedQuery: Query = new Query();
    const auditSelectedQueryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id')
    ];
    auditSelectedQuery.OPath = `IdRefLibrary='${this.selectedLibraryId}'`;

    auditSelectedQuery.ObjectType = ObjectKeys.AUDIT;
    auditSelectedQuery.Columns = auditSelectedQueryColumns;

    const auditSelectedQueryDocument: QueryObjectsModel = new QueryObjectsModel();
    auditSelectedQueryDocument.ObjectQueries = [auditSelectedQuery];

    return this.coreDataService
      .executeReadObjectsQuery(auditSelectedQueryDocument);
  }

  public onCreateLibrary(event): void {
    event.preventDefault();
    if (!this.assignCategorizingQuestions) {
      this.generateLabrayItems$ = this.persistLibrary();
    }
    else {
      this.generateLabrayItems$ = this.persistLibrary().pipe(
        switchMap(
          (library) =>
            this.persistCategory(library.Id).pipe(
              switchMap((category) =>
                this.persistQuestion(category.Id).pipe(
                  switchMap((questions) =>
                    this.persistResponse(questions)
                      .pipe(map((questions) => ({ library })))
                  )
                )
              )
            )
        )
      );
    }
    this.subscriptions.push(
      this.generateLabrayItems$.subscribe(
        (data) => {
          if (data.library) {
            this.getLibraryById(this.columns, data.library.Id);
          }
          else {
            this.getLibraryById(this.columns, data.Id);
          }
        }, () => {
          this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });
        }, () => {
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Success'),
            type: ToastType.SUCCESS
          });
        }
      )
    );
  }

  public persistQuestion(ParentId) {
    const query: PersistObjectsModel = new PersistObjectsModel();
    let count = 1;
    let auditItemQuestionPersistQuery: TargetObjectData[][] = [[]];
    this.libCategory.BibQuestions?.forEach((question) => {
      let auditItemPersistObjectData_Question: TargetObjectData = new TargetObjectData();
      auditItemPersistObjectData_Question.Mode = PersistMode.Insert;
      auditItemPersistObjectData_Question.ObjectKey = new ObjectKey();
      auditItemPersistObjectData_Question.ObjectKey.ObjectType = ObjectKeys.BIBQUESTION;
      const questionColumns: TargetColumnValue[] = [
        { Name: 'ParentId', Value: ParentId },
        {
          Name: 'Designation',
          Value: question.Designation
        },
        { Name: 'Description', Value: question.Description },
        { Name: 'Factor', Value: question.Factor },
        { Name: 'SortNr', Value: question.SortNr },
        { Name: 'SeqNr', Value: count }
      ];
      auditItemPersistObjectData_Question.TargetColumns = questionColumns;
      auditItemQuestionPersistQuery[0].push(
        auditItemPersistObjectData_Question
      );
      count++;
    });
    query.Objects = auditItemQuestionPersistQuery;
    return this.persistenceService.executePersistObjectsQuery(query);
  }

  public persistResponse(questions) {
    const categoryQuestions = questions[0];
    categoryQuestions.forEach((element, index) => {
      this.categoryQuestions[index].Id = element.Id;
    });
    const query: PersistObjectsModel = new PersistObjectsModel();
    let auditItemResponsePersistQuery: TargetObjectData[][] = [[]];
    this.categoryQuestions.forEach((question) => {
      let count = 0;
      question.BibResponses?.forEach((response) => {
        count++;
        let auditItemPersistObjectData_Response: TargetObjectData = new TargetObjectData();
        auditItemPersistObjectData_Response.Mode = PersistMode.Insert;
        auditItemPersistObjectData_Response.ObjectKey = new ObjectKey();
        auditItemPersistObjectData_Response.ObjectKey.ObjectType = ObjectKeys.BIBRESPONSE;
        const responseColumns: TargetColumnValue[] = [
          { Name: 'ParentId', Value: question.Id },
          { Name: 'Designation', Value: response.Designation },
          { Name: 'SeqNr', Value: count }
        ];
        auditItemPersistObjectData_Response.TargetColumns = responseColumns;
        auditItemResponsePersistQuery[0].push(
          auditItemPersistObjectData_Response
        );
      });
    });
    query.Objects = auditItemResponsePersistQuery;
    return this.persistenceService.executePersistObjectsQuery(query);
  }

  public openLibraryDialog(isUpdate?: boolean): void {
    this.isUpdate = isUpdate;
    if (isUpdate) {
      this.oldValueOfDesignation = this.library.Designation;
      this.oldValueOfKind = this.library.Kind;
    }
    this.addLibraryPopup.instance.show();
  }

  public openLibraryDetail(event): void {
    this.editMode=true;
    this.buttonAction = event.column.name;
    this.library = event.row?.data;
    this.rowIndex = event.row.dataIndex;
    this.openLibraryDialog(true);
  }

  public prepareQueryToGetQuestionsByCategory(): QueryObjectsModel {
    const subQueryCategories: Query = new Query();
    const subQueryCategoryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation'),
      this.coreDataService.createQueryColumn('SortNr', 'SortNr')
    ];
    subQueryCategories.ObjectType = ObjectKeys.BIBCATEGORY;
    subQueryCategories.Columns = subQueryCategoryColumns;
    subQueryCategories.OPath = `Id='${this.categorizingQuestionsId}'`;
    subQueryCategories.Columns = subQueryCategoryColumns;
    const subQueryQuestions: Query = new Query();
    subQueryQuestions.Name = 'BibQuestions';
    subQueryQuestions.OPath = 'BibQuestions';
    const subQueryCategoriesColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation'),
      this.coreDataService.createQueryColumn('Title', 'Title'),
      this.coreDataService.createQueryColumn('Score', 'Score'),
      this.coreDataService.createQueryColumn('Factor', 'Factor'),
      this.coreDataService.createQueryColumn('SortNr', 'SortNr'),
      this.coreDataService.createQueryColumn(
        'Max(BibResponses.ToFloat(Score))',
        'MaxScore'
      )
    ];
    subQueryQuestions.Columns = subQueryCategoriesColumns;
    const subQueryResponses: Query = new Query();
    subQueryResponses.Name = 'BibResponses';
    subQueryResponses.OPath = 'BibResponses';
    const subQueryResponseColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation'),
    ];
    subQueryResponses.Columns = subQueryResponseColumns;
    subQueryQuestions.ObjectQueries = [subQueryResponses];
    subQueryCategories.ObjectQueries = [subQueryQuestions];
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [subQueryCategories];
    return queryDocument;
  }

  public deleteLibrary(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.LIBRARY, this.selectedLibraryId)
        .subscribe((deletedLibrary) => {
          if (deletedLibrary?.Id) {
            this.libraries = this.libraries.filter(
              (category) => category.Id != deletedLibrary?.Id
            );
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Delete-Library-Success'),
              type: ToastType.SUCCESS,
            });
          } else {
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Error'),
              type: ToastType.ERROR,
            });
          }
        }, error => {
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Error'),
            type: ToastType.ERROR
          });
        })
    );
    this.deleteLibraryPopup.instance.hide();
  }

  public openDeleteLibraryDialog(event): void {
    this.selectedLibraryId = event.row?.data.Id;
    this.readAuditSelectedByLibraryId().subscribe((data) => {
      if (data.length > 0) {
        this.isDeleteWarning = true;
      }
      this.deleteLibraryPopup.instance.show();
    })
  }

  public emptyData(): void {
    this.form.instance.resetValues();
    this.buttonAction = '';
    this.library = new Library(null);
  }

  public onHiding(): void {
    this.emptyData();
  }

  public onClosePopup(persist?: boolean): void {
    this.editMode=false;
    if (!persist) {
      if (this.isUpdate) {
        this.library.Designation = this.oldValueOfDesignation;
        this.library.Kind = this.oldValueOfKind;
        this.isUpdate = false;
      }
    }
    this.addLibraryPopup.instance.hide();
    this.deleteLibraryPopup.instance.hide();
    this.isDeleteWarning = false;
    this.emptyData();
  }

  public returnDxItemCssClass(className: string): string {
    return className;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
