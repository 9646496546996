import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeoDsCoreDataService, GeoDsPersistenceService, GUID, ObjectKey, PersistMode, PersistObjectModel, Query, QueryColumn, QueryObjectsModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { BibResponse, SharedDataService } from '@wissenswerft/organizational-structure';
import { EditingMode, GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxFormComponent, DxPopupComponent, DxSelectBoxComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { Column } from 'devextreme/ui/data_grid';
import { FavoritesService } from 'libs/favorites/src/lib/favorites.service';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { ObjectTypeNames } from '../favorite-list/favorite-list.component';
import { Category, Question } from './response-list.model';


@Component({
  selector: 'digitalization-tool-response-list',
  templateUrl: './response-list.component.html',
  styleUrls: ['./response-list.component.scss']
})

export class ResponseListComponent implements OnInit, OnDestroy {
  @ViewChild('responsesGrid') responsesGrid: GridComponent;
  @ViewChild('addResponsePopup') addResponsePopup: DxPopupComponent;
  @ViewChild('deleteResponsePopup') deleteResponsePopup: DxPopupComponent;
  @ViewChild('questionsList') questionsList: DxSelectBoxComponent;
  @ViewChild('categoryList') categoryList: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;

  public response: BibResponse = new BibResponse();
  public responses: BibResponse[] = [];
  private subscriptions: Subscription[] = [];
  public selectedResponseId: string;
  public buttonAction = '';
  public rowIndex: number;
  public oldValueOfDesination = '';
  public oldValueOfCategory = '';
  public oldValueOfScore;
  public oldValueOfSortNr: number;
  public showLoader = true;
  public isUpdate = false;
  public columns: QueryColumn[] = [];
  public fromUngroupedDataCategory: DataSource;
  public fromUngroupedDataQuestion: DataSource;
  public isDeleteWarning = false;
  public editingMode = EditingMode.Cell;
  public allQuestions: Question[] = [];
  public allCategories: Category[] = [];
  public categorySelected = false;
  public selectedResponseCategory = '';


  public cancelButtonOptions = {
    text: this.dataService.res('Dt-Cancel'),
    onClick: () => this.onClosePopup()
  };

  public createButtonOptions = {
    text: this.dataService.res('Dt-Create'),
    useSubmitBehavior: true
  };

  public createAdditionalAnswerButtonOptions = {
    text: this.dataService.res('Dt-Response-Create-Additional-Answer-Option'),
    useSubmitBehavior: false,
    onClick: (e) => {
      this.createAdditionalAnswer()
    }
  };

  public editButtonOptions = {
    text: this.dataService.res('Dt-Finished'),
    useSubmitBehavior: true
  };

  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Dt-Response-Columns-Header-Category'),
      dataField: 'Category',
      dataType: 'string',
      visibleIndex: 0,
      allowEditing: false,
      groupIndex: 1
    },
    {
      caption: this.dataService.res('Dt-Response-Columns-Header-Question'),
      dataField: 'questionDesignation',
      dataType: 'string',
      visibleIndex: 1,
      allowEditing: false,
      groupIndex: 1
    },
    {
      caption: this.dataService.res('Dt-Response-Columns-Header-Designation'),
      dataField: 'Designation',
      visibleIndex: 2,
      allowEditing: false,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Dt-Response-Columns-Header-Score'),
      dataField: 'Score',
      dataType: 'number',
      visibleIndex: 3,
      alignment: 'left'
    },
    {
      caption: this.dataService.res('Dt-Grid-Sort-Order'),
      dataField: 'SortNr',
      dataType: 'number',
      alignment: 'left',
      visibleIndex: 4
    }
  ];

  constructor(
    private persistenceService: GeoDsPersistenceService,
    private coreDataService: GeoDsCoreDataService,
    public dataService: DataService,
    private appService: AppService,
    private sharedDataService: SharedDataService,
    public favoritesService: FavoritesService
  ) { }

  ngOnInit(): void {
    this.columnsHeader.push({
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [
        {
          icon: 'edit',
          text: this.dataService.res('Dt-Edit'),
          onClick: (e) => {
            this.openResponseDetail(e);
          }
        },
        {
          icon: 'trash',
          text: this.dataService.res('Dt-Delete'),
          onClick: (e) => {
            this.openDeleteResponseDialog(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-inactive',
          text: 'add favorite',
          visible: (e) => !this.isFavoriteVisible(e),
          onClick: (e) => {
            this.addFavorite(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-active',
          text: 'remove favorite',
          visible: (e) => this.isFavoriteVisible(e),
          onClick: (e) => {
            this.deleteFavorite(e);
          }
        }
      ]
    });

    this.columns.push(
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Question'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('ref:!.ParentId', 'Category'),
      this.coreDataService.createQueryColumn('!.Designation', 'questionDesignation'),
      this.coreDataService.createQueryColumn('!.!.!.Designation', 'libraryDesignation'),
      this.coreDataService.createQueryColumn('Designation', 'Designation'),
      this.coreDataService.createQueryColumn('SeqNr', 'SeqNr'),
      this.coreDataService.createQueryColumn('Score', 'Score'),
      this.coreDataService.createQueryColumn('SortNr', 'SortNr'),
      this.coreDataService.createQueryColumn('!.ParentId', 'CategoryId'),
      this.coreDataService.createQueryColumn(
        "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
        'IsFavorite'
      ),
      this.coreDataService.createQueryColumn(
        "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
        'BoxDetailId'
      )
    );

    this.getQuestions().subscribe((questions) => {
      this.allQuestions = questions;
      this.fromUngroupedDataQuestion = new DataSource({
        store: new ArrayStore({
          data: questions,
          key: 'Id',
        }),
        group: 'Category',
      });
    });

    this.getCategories().subscribe((categories) => {
      this.allCategories = categories;
      this.fromUngroupedDataCategory = new DataSource({
        store: new ArrayStore({
          data: categories,
          key: 'Id',
        }),
        group: 'Library',
      });
    });

    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.BIBRESPONSE, this.columns)
        .pipe(
          switchMap((responses) => {
            return this.dataService
              .loadListDataSource(ObjectKeys.BIBQUESTION, 'Id')
              .pipe(map((questionsData) => ({ responses, questionsData })));
          })
        )
        .subscribe(({ responses, questionsData }) => {
          this.dataService.cachedQuestions = questionsData.Data;
          this.responses = responses;
          this.subscriptions.push(this.dataService.favoriteName$.subscribe((data) => {
            if (this.dataService.favoriteData.get(data)?.length !== 0) {
              this.responsesGrid.dxDataGrid.instance.clearFilter();
              this.responsesGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', this.dataService.favoriteData.get(data));
            }
            else {
              this.responsesGrid.dxDataGrid.instance.clearFilter();
              this.responsesGrid.dxDataGrid.instance.refresh();
            }
          }))
          if (localStorage.getItem('responseName')?.length == 0) {
            this.responsesGrid.dxDataGrid.instance.clearFilter();
            this.responsesGrid.dxDataGrid.instance.refresh();
          } else {
            this.responsesGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', localStorage.getItem('responseName'));
          }
          this.showLoader = false;
        }, error => {
          console.error(error);
          this.showLoader = false;
        })
    );

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((category: BibResponse, selectedItem?: string) => {
      this.responses.push(category);
      this.responsesGrid.refreshGrid();
    }, error => {
      console.error(error);
    }));
  }
  public onCellPrepared(e): void {
    if (e.rowType === "group" && e.column.dataField === "questionDesignation" && e.columnIndex === 2) {
      const currentQuestion = e.data.key;
      const addAnswerContainer = document.createElement("div");
      addAnswerContainer.style.float = 'right';
      const addAnswerButton = document.createElement("img");
      addAnswerButton.setAttribute('src', "assets/images/plus.svg");
      addAnswerButton.style.width = '25px';
      addAnswerButton.style.cursor = 'pointer';
      addAnswerContainer.append(addAnswerButton);

      e.cellElement.append(addAnswerContainer);
      addAnswerButton.addEventListener('click', () => {
        this.allQuestions.forEach(question => {
          if (question.QuestionDesignation === currentQuestion) {
            this.categoryList.value = question.CategoryId;
            this.response.ParentId = question.Id;
          }
        })
        this.categorySelected = true;
        this.addResponsePopup.instance.show();
      })
    }
  }
  public isFavoriteVisible(e) {
    if (e.row?.data.IsFavorite === 1) {
      return true;
    } else {
      return false;
    }
  }

  public addFavorite(e): void {
    this.favoritesService.persistFavoriten(GUID.newGUID(), ObjectTypeNames.AudBibResponse, e.row?.data.Id, e.row?.data.Designation).subscribe((data) => {
      this.responsesUpdateState(e, 'add', data);
    });
  }

  public deleteFavorite(e): void {
    this.favoritesService.deleteFavoriten(e.row?.data.BoxDetailId);
    this.responsesUpdateState(e, 'delete');
  }

  public responsesUpdateState(e, state: string, favoriteData?): void {
    let selectedIndex = this.responses.findIndex((response) => response.Id === e.row?.data.Id);
    if (state === 'add') {
      const clonedItem = { ...e.row?.data, IsFavorite: 1, BoxDetailId: favoriteData.Id };
      this.responses[selectedIndex] = clonedItem;
    } else {
      const clonedItem = { ...e.row?.data, IsFavorite: 0 };
      this.responses[selectedIndex] = clonedItem;
    }
  }

  public getResponses(columns: QueryColumn[]) {
    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.BIBRESPONSE, columns)
        .subscribe((responsesData) => {
          this.responses = responsesData;
          this.responsesGrid.refreshGrid();
        })
    );
  }

  public getQuestions(): Observable<Question[]> {

    const questionSelectedQuery: Query = new Query();

    let columnsQuestion: QueryColumn[] = [
      this.coreDataService.createQueryColumn("Concat(ref:ParentId,'/', Designation)", 'FullPath'),
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Category'),
      this.coreDataService.createQueryColumn('Designation', 'QuestionDesignation'),
      this.coreDataService.createQueryColumn('ParentId', 'CategoryId')
    ]

    let opath = "";
    if (this.categoryList?.selectedItem.Id) {
      opath = `ParentId = '${this.categoryList?.selectedItem.Id}'`;
    }

    return this.dataService
      .readObjects(ObjectKeys.BIBQUESTION, columnsQuestion, opath)
  }

  public getCategories(): Observable<Category[]> {
    let columnsCategory: QueryColumn[] = [
      this.coreDataService.createQueryColumn("Concat(ref:ParentId,'/', Designation)", 'FullPath'),
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Library'),
      this.coreDataService.createQueryColumn('Designation', 'CategoryDesignation')
    ]
    return this.dataService
      .readObjects(ObjectKeys.BIBCATEGORY, columnsCategory)
  }


  public getResponseById(columns: QueryColumn[], id: string, closePopup: boolean) {
    let opath = 'Id=' + "'" + id + "'";
    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.BIBRESPONSE, columns, opath)
        .subscribe((responseData) => {
          if (this.buttonAction == 'edit') {
            let selectedIndex = this.responses.findIndex((response) => response.Id === id);
            const clonedItem = { ...this.response };
            this.responses[selectedIndex] = clonedItem;
            this.appService.callNotification({
              message: this.dataService.res('Dt-Response-Succees-update'),
              type: ToastType.SUCCESS
            });
          } else {
            this.sharedDataService.updateGridData(responseData[0]);
            this.responsesGrid.refreshGrid();
            this.appService.callNotification({
              message: this.dataService.res('Dt-Response-Succees-insert'),
              type: ToastType.SUCCESS
            });
          }
          if (closePopup) {
            this.onClosePopup(true);
          } else {
            let categoryId = this.response["ParentId"];
            this.response.Designation = null;
            this.response.Score = null;
            this.response.SortNr = null;
            this.form.instance.resetValues();
            this.response["ParentId"] = categoryId;
          }
        })
    );
  }

  public readAuditItemSelectedByResponseId(): Observable<any> {
    const auditItemSelectedQuery: Query = new Query();
    const auditItemSelectedQueryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id')
    ];
    auditItemSelectedQuery.OPath = `IdRefBibResponse='${this.selectedResponseId}'`;

    auditItemSelectedQuery.ObjectType = ObjectKeys.AUDITITEM;
    auditItemSelectedQuery.Columns = auditItemSelectedQueryColumns;

    const auditItemSelectedQueryDocument: QueryObjectsModel = new QueryObjectsModel();
    auditItemSelectedQueryDocument.ObjectQueries = [auditItemSelectedQuery];

    return this.coreDataService
      .executeReadObjectsQuery(auditItemSelectedQueryDocument);
  }

  public persistResponse(): Observable<any> {
    const responsePersistQuery: TargetObjectData = new TargetObjectData();
    responsePersistQuery.ObjectKey = new ObjectKey();
    responsePersistQuery.ObjectKey.ObjectType = ObjectKeys.BIBRESPONSE;
    if (this.buttonAction === 'edit') {
      responsePersistQuery.Mode = PersistMode.Update;
      responsePersistQuery.ObjectKey.Id = this.response.Id;
      this.responsesGrid.refreshGrid();
    } else {
      responsePersistQuery.Mode = PersistMode.Insert;
    }
    const responseColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: this.questionsList.selectedItem.Id },
      { Name: 'Designation', Value: this.response.Designation },
      { Name: 'Score', Value: this.response.Score },
      { Name: 'SortNr', Value: this.response.SortNr }

    ];
    responsePersistQuery.TargetColumns = responseColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = responsePersistQuery;
    return this.persistenceService.executePersistObjectQuery(persistObject);
  }

  public onCreateResponse(event): void {
    event.preventDefault();
    this.subscriptions.push(
      this.persistResponse()
        .subscribe((data) => {
          this.getResponseById(this.columns, data.Id, true);
          this.responsesGrid.updateDataGrid(this.responses);
          this.getResponses(this.columns);
        }, () => {
          this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });
          this.responsesGrid.repaintGrid();
        })
    );
  }

  public createAdditionalAnswer(): void {
    this.subscriptions.push(
      this.persistResponse().subscribe((response) => {
        this.getResponseById(this.columns, response.Id, false)
      }, () => {
        this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });

      }))
  }

  public deleteResponse(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.BIBRESPONSE, this.selectedResponseId)
        .subscribe((deletedResponse) => {
          if (deletedResponse?.Id) {
            this.responses = this.responses.filter(
              (response) => response.Id != deletedResponse?.Id
            );
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Delete-Response-Success'),
              type: ToastType.SUCCESS
            });
          } else {
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Error'),
              type: ToastType.ERROR
            });
          }
        }, error => {
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Error'),
            type: ToastType.ERROR
          });
        })
    );
    this.deleteResponsePopup.instance.hide();
  }

  public openDeleteResponseDialog(event): void {
    this.selectedResponseId = event.row?.data.Id;
    this.readAuditItemSelectedByResponseId().subscribe((data) => {
      if (data?.length > 0) {
        this.isDeleteWarning = true;
      }
      this.deleteResponsePopup.instance.show();
    })
  }

  public openResponseDialog(isUpdate?: boolean): void {
    this.isUpdate = isUpdate;
    this.oldValueOfCategory = this.selectedResponseCategory;
    this.oldValueOfDesination = this.response.Designation;
    this.oldValueOfScore = this.response.Score;
    this.oldValueOfSortNr = this.response.SortNr;
    this.addResponsePopup.instance.show();
  }

  public openResponseDetail(event): void {
    this.buttonAction = event.column.name;
    this.response = event.row?.data;
    this.rowIndex = event.row.dataIndex;
    this.selectedResponseCategory = event.row?.data.CategoryId;
    this.openResponseDialog(true);
  }

  public onClosePopup(persist?: boolean): void {
    if (!persist || this.isUpdate) {
      this.selectedResponseCategory = this.oldValueOfCategory;
      this.response.Designation = this.oldValueOfDesination;
      this.response.Score = this.oldValueOfScore;
      this.response.SortNr = this.oldValueOfSortNr;
      this.isUpdate = false;
    }
    this.emptyData();
    this.addResponsePopup.instance.hide();
    this.deleteResponsePopup.instance.hide();
    this.form.instance.resetValues();
  }

  public emptyData(): void {
    this.categorySelected = false;
    this.categoryList.instance.reset();
    this.addResponsePopup.instance.hide();
    this.response = new BibResponse(null);
    this.buttonAction = '';
    this.form.instance.resetValues();
  }

  public onCellDblClick(event): void {
    if (event.columnIndex > 1)
      this.responsesGrid.editCell(event.rowIndex, event.columnIndex)
  }

  public onRowUpdated(event) {
    const responsePersistQuery: TargetObjectData = new TargetObjectData();
    responsePersistQuery.ObjectKey = new ObjectKey();
    responsePersistQuery.ObjectKey.ObjectType = ObjectKeys.BIBRESPONSE;

    responsePersistQuery.Mode = PersistMode.Update;
    responsePersistQuery.ObjectKey.Id = event.oldData.Id;

    const responseColumns: TargetColumnValue[] = [
      {
        Name: 'Designation',
        Value: event.newData.Designation ? event.newData.Designation : event.oldData.Designation
      },
      {
        Name: 'Score',
        Value: event.newData.Score ? event.newData.Score : event.oldData.Score
      },
      {
        Name: 'SortNr',
        Value: event.newData.SortNr ? event.newData.SortNr : event.oldData.SortNr
      },
    ];
    responsePersistQuery.TargetColumns = responseColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = responsePersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((result) => {
      this.appService.callNotification({
        message: this.dataService.res('Dt-Response-Succees-update'),
        type: ToastType.SUCCESS
      });
    }, () => {
      this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });
    }));

  }

  public onHiding(): void {
    this.emptyData();
  }

  public returnDxItemCssClass(className: string): string {
    return className;
  }

  public categorySelect(event): void {
    this.subscriptions.push(
      this.getQuestions().subscribe((questions) => {
        this.allQuestions = questions;
        this.fromUngroupedDataQuestion = new DataSource({
          store: new ArrayStore({
            data: questions,
            key: 'Id',
          }),
          group: 'Category',
        });
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
