import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  GeoDsCoreDataService,
  GeoDsPersistenceService,
  GUID,
  Query,
  QueryObjectsModel,
  QueryColumnSortOrder
} from '@wissenswerft/core/data';
import { BibCategory, Library, SharedDataService } from '@wissenswerft/organizational-structure';
import { EditingMode, GridComponent, ToastType } from '@wissenswerft/ww-library';
import {
  DxFormComponent,
  DxPopupComponent,
  DxScrollViewComponent,
  DxSelectBoxComponent
} from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import {
  ObjectKey,
  PersistMode,
  PersistObjectModel,
  QueryColumn,
  TargetColumnValue,
  TargetObjectData
} from '@wissenswerft/core/data';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataService, ObjectKeys } from '../../services/data.service';

import { AppService } from '../../services/app.service';
import { FavoritesService } from 'libs/favorites/src/lib/favorites.service';
import { ObjectTypeNames } from '../favorite-list/favorite-list.component';

@Component({
  selector: 'digitalization-tool-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit, OnDestroy {
  @ViewChild('categoryGrid') categoryGrid: GridComponent;
  @ViewChild('addCategoryPopup') addCategoryPopup: DxPopupComponent;
  @ViewChild('deleteCategoryPopup') deleteCategoryPopup: DxPopupComponent;
  @ViewChild('libraryList') libraryList: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;
  @ViewChild(DxScrollViewComponent, { static: true })
  scrollView: DxScrollViewComponent;

  public library: Library = new Library();
  public libraries: Library[];
  public category: BibCategory = new BibCategory();
  public categories: BibCategory[];
  private subscriptions: Subscription[] = [];
  public buttonAction = '';
  public rowIndex: number;
  public selectedCategoryId: string;
  public deletePopupHeight: string;
  public oldValueOfLibrary = '';
  public oldValueOfDesination = '';
  public oldValueOfSortNumber: number;
  public isUpdate = false;
  public columns: QueryColumn[] = [];
  public isDeleteWarning = false;
  public showLoader = true;
  public editingMode = EditingMode.Cell;
  public createButtonOptions = {
    text: this.dataService.res('Dt-Create'),
    useSubmitBehavior: true,
  };

  public editButtonOptions = {
    text: this.dataService.res('Dt-Edit'),
    useSubmitBehavior: true,
  };

  public cancelButtonOptions = {
    text: this.dataService.res('Dt-Cancel'),
    onClick: () => this.onClosePopup(),
  };

  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Dt-Category-Columns-Header-Designation'),
      dataField: 'Designation',
      dataType: 'string',
      visibleIndex: 1,
    },
    {
      caption: this.dataService.res('Dt-Category-Columns-Header-Library'),
      dataField: 'Library',
      dataType: 'string',
      visibleIndex: 0,
      groupIndex: 0
    },
    {
      caption: this.dataService.res('Dt-Grid-Sort-Order'),
      dataField: 'SortNr',
      dataType: 'number',
      alignment: 'left',
      visibleIndex: 2
    }
  ];

  constructor(
    private persistenceService: GeoDsPersistenceService,
    private coreDataService: GeoDsCoreDataService,
    public dataService: DataService,
    private appService: AppService,
    private sharedDataService: SharedDataService,
    public favoritesService: FavoritesService
  ) { }

  ngOnInit(): void {
    this.columnsHeader.push({
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [
        {
          icon: 'edit',
          text: this.dataService.res('Dt-Edit'),
          onClick: (e) => {
            this.openCategoryDetail(e);
          },
        },
        {
          icon: 'trash',
          text: this.dataService.res('Dt-Delete'),
          onClick: (e) => {
            this.openDeleteCategoryDialog(e);
          },
        },
        {
          icon: 'like',
          cssClass: 'favorite-inactive',
          text: 'add favorite',
          visible: (e) => !this.isFavoriteVisible(e),
          onClick: (e) => {
            this.addFavorite(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-active',
          text: 'remove favorite',
          visible: (e) => this.isFavoriteVisible(e),
          onClick: (e) => {
            this.deleteFavorite(e);
          }
        }
      ],
    });

    this.columns.push(
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Library'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('SortNr', 'SortNr', QueryColumnSortOrder.Ascending),
      this.coreDataService.createQueryColumn('Designation', 'Designation'),
      this.coreDataService.createQueryColumn(
        "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
        'IsFavorite'
      ),
      this.coreDataService.createQueryColumn(
        "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
        'BoxDetailId'
      )
    );

    this.subscriptions.push(this.dataService.readObjects(ObjectKeys.BIBCATEGORY, this.columns).pipe(switchMap(categories => {
      return this.dataService.loadListDataSource(ObjectKeys.LIBRARY, "Id").pipe(map(libraryData => ({ categories, libraryData })));
    })).subscribe(({ categories, libraryData }) => {
      this.dataService.cachedLibrary = libraryData.Data;
      this.categories = categories;
      this.showLoader = false;
    }, error => {
      console.error(error);
      this.showLoader = false;
    }
    ))

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((category: BibCategory, selectedItem?: string) => {
      this.categories.push(category);
      this.categoryGrid.refreshGrid();
    }, error => {
      console.error(error);
    }));

    this.subscriptions.push(this.dataService.favoriteName$.subscribe((data) => {
      if (this.dataService.favoriteData.get(data)?.length !== 0) {
        this.categoryGrid.dxDataGrid.instance.clearFilter();
        this.categoryGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', this.dataService.favoriteData.get(data));
      }
      else {
        this.categoryGrid.dxDataGrid.instance.clearFilter();
        this.categoryGrid.dxDataGrid.instance.refresh();
      }
    }))
    if (localStorage.getItem('categoryName')?.length == 0) {
      this.categoryGrid?.dxDataGrid.instance.clearFilter();
      this.categoryGrid?.dxDataGrid.instance.refresh();
    } else {
      this.categoryGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', localStorage.getItem('categoryName'));
    }

  }

  public isFavoriteVisible(e) {
    if (e.row?.data.IsFavorite === 1) {
      return true;
    } else {
      return false;
    }
  }

  public addFavorite(e): void {
    this.favoritesService.persistFavoriten(GUID.newGUID(), ObjectTypeNames.AudBibCategory, e.row?.data.Id, e.row?.data.Designation).subscribe((data) => {
      this.categoriesUpdateState(e, 'add', data);
    });
  }

  public deleteFavorite(e): void {
    this.favoritesService.deleteFavoriten(e.row?.data.BoxDetailId);
    this.categoriesUpdateState(e, 'delete');
  }

  public categoriesUpdateState(e, state: string, favoriteData?): void {
    let selectedIndex = this.categories.findIndex((category) => category.Id === e.row?.data.Id);
    if (state === 'add') {
      const clonedItem = { ...e.row?.data, IsFavorite: 1, BoxDetailId: favoriteData.Id };
      this.categories[selectedIndex] = clonedItem;
    } else {
      const clonedItem = { ...e.row?.data, IsFavorite: 0 };
      this.categories[selectedIndex] = clonedItem;
    }
  }

  public getCategories(columns: QueryColumn[]) {
    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.BIBCATEGORY, columns)
        .subscribe((categoriesData) => {
          this.categories = categoriesData;
        })
    );
  }
  public getCategoryById(columns: QueryColumn[], id) {
    let opath = 'Id=' + "'" + id + "'";
    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.BIBCATEGORY, columns, opath)
        .subscribe((categoryData) => {
          if (this.buttonAction == 'edit') {
            let selectedIndex = this.categories.findIndex((category) => category.Id === id);
            const clonedItem = { ...this.category };
            this.categories[selectedIndex] = clonedItem;
            this.appService.callNotification({
              message: this.dataService.res('Dt-Category-Succees-update'),
              type: ToastType.SUCCESS
            });

          } else {
            this.sharedDataService.updateGridData(categoryData[0]);
            this.categoryGrid.refreshGrid();
            this.appService.callNotification({
              message: this.dataService.res('Dt-Category-Succees-insert'),
              type: ToastType.SUCCESS
            });
          }
          this.onClosePopup(true);
        })
    );
  }

  public persistCategory() {
    const categoryPersistQuery: TargetObjectData = new TargetObjectData();
    categoryPersistQuery.ObjectKey = new ObjectKey();
    categoryPersistQuery.ObjectKey.ObjectType = ObjectKeys.BIBCATEGORY;
    if (this.buttonAction === 'edit') {
      categoryPersistQuery.Mode = PersistMode.Update;
      categoryPersistQuery.ObjectKey.Id = this.category.Id;
    } else {
      categoryPersistQuery.Mode = PersistMode.Insert;
    }
    const categoryColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: this.libraryList.selectedItem.Id },
      { Name: 'Designation', Value: this.category.Designation },
      { Name: 'SortNr', Value: this.category.SortNr }
    ];
    categoryPersistQuery.TargetColumns = categoryColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = categoryPersistQuery;
    this.subscriptions.push(
      this.persistenceService
        .executePersistObjectQuery(persistObject)
        .subscribe((data) => {
          this.getCategoryById(this.columns, data.Id);
        }, () => {
          this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });
        })
    );
  }

  public readAuditItemSelectedByCategoryId(): Observable<any> {
    const auditItemSelectedQuery: Query = new Query();
    const auditItemSelectedQueryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id')
    ];
    auditItemSelectedQuery.OPath = `IdRefBibCategory='${this.selectedCategoryId}'`;

    auditItemSelectedQuery.ObjectType = ObjectKeys.AUDITITEM;
    auditItemSelectedQuery.Columns = auditItemSelectedQueryColumns;

    const auditItemSelectedQueryDocument: QueryObjectsModel = new QueryObjectsModel();
    auditItemSelectedQueryDocument.ObjectQueries = [auditItemSelectedQuery];

    return this.coreDataService
      .executeReadObjectsQuery(auditItemSelectedQueryDocument);
  }

  public onCreateCategory(event): void {
    event.preventDefault();
    this.persistCategory();
  }

  public deleteCategory(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.BIBCATEGORY, this.selectedCategoryId)
        .subscribe((deletedCategory) => {
          if (deletedCategory?.Id) {
            this.categories = this.categories.filter(
              (category) => category.Id != deletedCategory?.Id
            );
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Delete-Category-Success'),
              type: ToastType.SUCCESS,
            });
          } else {
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Error'),
              type: ToastType.ERROR,
            });
          }
        }, error => {
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Error'),
            type: ToastType.ERROR
          });
        })
    );
    this.deleteCategoryPopup.instance.hide();
  }

  public openDeleteCategoryDialog(event): void {
    this.selectedCategoryId = event.row?.data.Id;
    this.readAuditItemSelectedByCategoryId().subscribe((data) => {
      if (data.length > 0) {
        this.isDeleteWarning = true;
      }
      this.deleteCategoryPopup.instance.show();
    })
  }

  public openCategoryDialog(isUpdate?: boolean): void {
    this.isUpdate = isUpdate;
    this.oldValueOfDesination = this.category.Designation;
    this.oldValueOfLibrary = this.library.Designation;
    this.oldValueOfSortNumber = this.category.SortNr;
    this.addCategoryPopup.instance.show();
  }

  public openCategoryDetail(event): void {
    this.buttonAction = event.column.name;
    this.category = event.row?.data;
    this.rowIndex = event.row.dataIndex;
    this.openCategoryDialog(true);
  }

  public emptyData(): void {
    this.category = new BibCategory(null);
    this.buttonAction = '';
  }
  public clearWindow(): void {
    this.addCategoryPopup.instance.hide();
    this.form.instance.resetValues();
  }

  public onHiding(): void {
    this.emptyData();
  }

  public onClosePopup(persist?: boolean): void {
    if (!persist) {
      if (this.isUpdate) {
        this.category.Designation = this.oldValueOfDesination;
        this.library.Designation = this.oldValueOfLibrary;
        this.category.SortNr = this.oldValueOfSortNumber;
        this.isUpdate = false;
      }
    }
    this.isDeleteWarning = false;
    this.emptyData();
    this.clearWindow();
    this.addCategoryPopup.instance.hide();
    this.deleteCategoryPopup.instance.hide();
  }

  public onCellDblClick(event): void {
    if (event.columnIndex > 0)
      this.categoryGrid.editCell(event.rowIndex, event.columnIndex)
  }

  public onRowUpdated(event) {
    const categoryPersistQuery: TargetObjectData = new TargetObjectData();
    categoryPersistQuery.ObjectKey = new ObjectKey();
    categoryPersistQuery.ObjectKey.ObjectType = ObjectKeys.BIBCATEGORY;

    categoryPersistQuery.Mode = PersistMode.Update;
    categoryPersistQuery.ObjectKey.Id = event.oldData.Id;

    const categoryColumns: TargetColumnValue[] = [
      {
        Name: 'Designation',
        Value: event.newData.Designation ? event.newData.Designation : event.oldData.Designation
      },
      {
        Name: 'SortNr',
        Value: event.newData.SortNr ? event.newData.SortNr : event.oldData.SortNr
      },
    ];
    categoryPersistQuery.TargetColumns = categoryColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = categoryPersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((result) => {
      this.appService.callNotification({
        message: this.dataService.res('Dt-Category-Succees-update'),
        type: ToastType.SUCCESS
      });
    }, () => {
      this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });
    }));

  }

  public returnDxItemCssClass(className: string): string {
    return className;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
