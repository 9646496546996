
export class Configuration {
    constructor(
        public OAuth2: OAuth2,
        public WebApi: WebApi,
        public API_URL: string,
        public CLIENT_ID: string,
        public CLIENT_SECRET: string
    ) { }
}

export class WebApi {
    constructor(public BaseURL: string, public ServiceURLs: SpaceOneWebApiServiceURLs | GeoDsWebApiServiceURLs) { }
}

export class SpaceOneWebApiServiceURLs {
    constructor(
        public DynamicContentItem: string,
        public ItemSocialMetaData: string,
        public Items: string,
        public Account_Generate_Token: string,
        public Account_Profile: string,
        public Account_Avatar: string,
        public AccountAvatarById: string,
        public Account_Refresh_Token: string,
        public DefaultDefinitionByTypeKey: string,
        public Accounts: string,
        public SPOQL_Items: string,
        public Comments: string,
        public CommentDeleteById: string,
        public CommentsByItemId: string,
        public Notes: string,
        public NoteByItemId: string,
        public CreateItem: string,
        public UpdateOrDeleteItem: string,
        public EnumProperty: string,
        public Charts: string,
        public Assets: string,
        public AssetsById: string,
        public NoteImagesById: string,
        public DeleteNoteById: string,
        public Documents: string,
        public DocumentById: string,
        public DeleteDocumentUri: string,
        public DownloadDocument: string,
        public PsApplications: string
    ) { }
}

export class GeoDsWebApiServiceURLs {
    constructor(
        public Account_Generate_Token: string,
        public Account_Profile: string,
        public Account_ReadCurrentProfile: string,
        public Account_Refresh_Token: string,
        public ReadObjects: string,
        public ReadListDataSource: string,
        public PersistObject: string,
        public PersistObjects: string,
        public ObjectTypeInfo: string,
        public ExportDocumentResult: string
    ) { }
}

export class OAuth2 {
    constructor(
        public ClientId: string,
        public ClientSecret: string
    ) { }
}
