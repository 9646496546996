import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IToast } from '@wissenswerft/ww-library';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private notificationSubject: Subject<IToast> = new Subject();
  public notification$: Observable<IToast> = this.notificationSubject.asObservable();

  constructor() {}

  public callNotification(notification: IToast): void {
    this.notificationSubject.next(notification);
  }
}
