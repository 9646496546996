import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Column } from 'devextreme/ui/data_grid';
import { DxPopupComponent, DxScrollViewComponent } from 'devextreme-angular';
import { AuditService } from '../shared/audit/audit.service';
import { Router } from '@angular/router';
import {
  GeoDsCoreDataService, GUID,
} from '@wissenswerft/core/data';
import { ObjectKeys, DataService } from '../../services/data.service';
import { AppService } from '../../services/app.service';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Subscription } from 'rxjs';
import {
  Audit,
  SharedDataService
} from '@wissenswerft/organizational-structure';
import { MatStepper } from '@angular/material/stepper';
import { FavoritesService } from 'libs/favorites/src/lib/favorites.service';
import { ObjectTypeNames } from '../favorite-list/favorite-list.component';
@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("auditGrid", { static: false }) auditGrid: GridComponent;
  @ViewChild('addAuditPopup') addAuditPopup: DxPopupComponent;
  @ViewChild('deleteAuditPopup') deleteAuditPopup: DxPopupComponent;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(DxScrollViewComponent, { static: true })
  scrollView: DxScrollViewComponent;

  public audits: Audit[];
  public selectedAuditId: string;
  public auditCompanyId = "";
  private subscriptions: Subscription[] = [];
  public showLoader = true;
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Dt-TreeList-Company'),
      dataField: 'Company',
      groupIndex: 0,
      visibleIndex: 0,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-AuditDate'),
      dataField: 'AuditDate',
      visibleIndex: 5,
      dataType: 'date',
      format: (date) => this.DateFormatter(date)

    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-Designation'),
      dataField: 'Designation',
      visibleIndex: 1,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-Score'),
      dataField: 'Score',
      visibleIndex: 3,
      dataType: 'number',
      alignment: 'left'
    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-MaxScore'),
      dataField: 'MaxScore',
      visibleIndex: 4,
      dataType: 'number',
      alignment: 'left'
    },
    {
      caption: this.dataService.res('Dt-Audit-Columns-Header-Percentage'),
      dataField: 'Percentage',
      visibleIndex: 2,
      dataType: 'number',
      alignment: 'left',
      customizeText: text => { return text.value + ' %' }
    }
  ];
  public popupOpened = false;

  constructor(
    private auditService: AuditService,
    private router: Router,
    private coreDataService: GeoDsCoreDataService,
    public dataService: DataService,
    private appService: AppService,
    private sharedDataService: SharedDataService,
    public favoritesService: FavoritesService
  ) { }

  public ngOnInit(): void {
    this.columnsHeader.push({
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [
        {
          icon: 'parentfolder',
          text: this.dataService.res('Dt-Edit'),
          onClick: (e) => {
            this.auditCompanyId = e.row?.data.CompanyId;
            this.navigateToEvaluation(
              e.row?.data.Company,
              e.row?.data.Id,
              e.row?.data.LibraryId,
              e.row?.data.Designation
            );
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-inactive',
          text: 'add favorite',
          visible: (e) => !this.isFavoriteVisible(e),
          onClick: (e) => {
            this.addFavorite(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-active',
          text: 'remove favorite',
          visible: (e) => this.isFavoriteVisible(e),
          onClick: (e) => {
            this.deleteFavorite(e);
          }
        },
        {
          icon: 'trash',
          text: this.dataService.res('Dt-Delete'),
          onClick: (e) => {
            this.openDeleteAuditDialog(e);
          }
        }
      ]
    });
    this.getAudits();

    this.subscriptions.push(
      this.sharedDataService.updateGridData$.subscribe(
        (audit: Audit) => {
          this.audits.push(audit);
        },
        (error) => {
          this.showLoader = false;
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Error'),
            type: ToastType.ERROR
          });
        }
      )

    );
  }

  public ngAfterViewInit(): void {
    if ((history.state.companyName === undefined) && (localStorage.getItem('companyName').length === 0)) {
      this.auditGrid.dxDataGrid.instance.clearFilter();
      this.auditGrid.dxDataGrid.instance.refresh();
    }
    this.dataService.companyName$.subscribe((name) => {
      if (name === undefined) {
        this.auditGrid.dxDataGrid.instance.clearFilter();
        this.auditGrid.dxDataGrid.instance.columnOption('Company', 'filterValue', name);
      }
      else {
        this.auditGrid.dxDataGrid.instance.clearFilter();
        this.auditGrid.dxDataGrid.instance.refresh();
      }
    })

  }

  public getAudits(): void {
    const auditQuery = this.auditService.prepareQueryToGetAudits();
    this.subscriptions.push(
      this.coreDataService.executeReadObjectsQuery(auditQuery).subscribe(
        (data) => {
          this.audits = data;
          this.showLoader = false;
        },
        (error) => {
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Error'),
            type: ToastType.ERROR
          });
        }
      )
    );
  }

  public isFavoriteVisible(e) {
    if (e.row?.data.IsFavorite === 1) {
      return true;
    } else {
      return false;
    }
  }

  public DateFormatter(date: any): any {
    // dd/mm/yyyy
    return date.toLocaleDateString('en-GB')
  }

  public auditsUpdateState(e, state: string, favoriteData?): void {
    let selectedIndex = this.audits.findIndex((audit) => audit.Id === e.row?.data.Id);
    if (state === 'add') {
      const clonedItem = { ...e.row?.data, IsFavorite: 1, BoxDetailId: favoriteData.Id };
      this.audits[selectedIndex] = clonedItem;
    } else {
      const clonedItem = { ...e.row?.data, IsFavorite: 0 };
      this.audits[selectedIndex] = clonedItem;
    }
  }

  public addFavorite(e): void {
    this.favoritesService.persistFavoriten(GUID.newGUID(), ObjectTypeNames.AudAudit, e.row?.data.Id, e.row?.data.Designation).subscribe((data) => {
      this.auditsUpdateState(e, 'add', data);
    });;
  }

  public deleteFavorite(e): void {
    this.favoritesService.deleteFavoriten(e.row?.data.BoxDetailId);
    this.auditsUpdateState(e, 'delete');
  }

  public openAddAuditDialog(): void {
    this.popupOpened = true;
  }

  public openDeleteAuditDialog(event): void {
    this.selectedAuditId = event.row.data.Id;
    this.deleteAuditPopup.instance.show();
    this.scrollView?.instance.scrollTo(0);
  }

  public deleteAudit(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.AUDIT, this.selectedAuditId)
        .subscribe(
          (deletedAudit) => {
            if (deletedAudit?.Id) {
              this.audits = this.audits.filter(
                (audit) => audit.Id != deletedAudit?.Id
              );
              this.appService.callNotification({
                message: this.dataService.res('Dt-Notification-Delete-Audit-Success'),
                type: ToastType.SUCCESS
              });
            }
          },
          (error) => {
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Error'),
              type: ToastType.ERROR
            });
          }
        )
    );

    this.onCloseDeletePopup();
  }

  public onCloseDeletePopup(): void {
    this.deleteAuditPopup.instance.hide();
  }

  public navigateToEvaluation(
    companyName: string,
    auditId: string,
    libraryId: string,
    auditDesignation: string
  ): void {
    localStorage.setItem('addedAuditId', auditId);
    localStorage.setItem('libraryId', libraryId);
    localStorage.setItem('auditDesignation', auditDesignation);
    localStorage.setItem('auditCompanyName', companyName);
    localStorage.setItem('auditCompanyId', this.auditCompanyId);
    this.router.navigate(['evaluation'], {
      state: {
        auditId: auditId,
        companyName: companyName,
        libraryId: libraryId,
        auditDesignation: auditDesignation
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
