import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Column } from 'devextreme/ui/data_grid';
import { EditingMode, GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxPopupComponent, DxScrollViewComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { BibQuestion, BibResponse, BibCategory, SharedDataService } from '@wissenswerft/organizational-structure';
import { DataService } from '../../services/data.service';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, QueryColumn, TargetColumnValue, TargetObjectData, QueryObjectsModel, Query, PersistObjectsModel } from '@wissenswerft/core/data';
import { ObjectKeys } from '../../services/data.service';
import { AppService } from '../../services/app.service';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GUID } from '@wissenswerft/core/data';
import { FavoritesService } from 'libs/favorites/src/lib/favorites.service';
import { ObjectTypeNames } from '../favorite-list/favorite-list.component';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { QuestionListService } from './question-list.service';

export enum GridPersistMode {
  INSERT = 'insert',
  UPDATE = 'update',
  REMOVE = 'remove'
}
export interface Libraries {
  Id: string;
  Library: string;
  LibraryDesignation: string;
}
@Component({
  selector: 'digitalization-tool-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss'],
})
export class QuestionListComponent implements OnInit, OnDestroy {
  @ViewChild('questionGrid') questionGrid: GridComponent;
  @ViewChild('categoriesList') categoriesList: DxSelectBoxComponent;
  @ViewChild('confirmPopup') confirmPopup: DxPopupComponent;
  @ViewChild('addQuestionPopup') addQuestionPopup: DxPopupComponent;
  @ViewChild('responsesGrid', { static: false }) responsesGrid: GridComponent;
  @ViewChild('deleteQuestionPopup') deleteQuestionPopup: DxPopupComponent;
  @ViewChild(DxScrollViewComponent, { static: true })
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();

  public assignedAnswersColumns: Column[] = [
    {
      dataField: 'Designation',
      caption: this.dataService.res('Dt-Question-List-Answer'),
      dataType: 'string'
    },
    {
      dataField: 'Score',
      alignment:'left',
      caption: this.dataService.res('Dt-Question-List-Score'),
      dataType: 'number'
    }
  ];

  private subscriptions: Subscription[] = [];
  public buttonAction = '';
  public rowIndex: number;
  public question: BibQuestion = new BibQuestion();
  public questions: BibQuestion[] = [];
  public response: BibResponse = new BibResponse();
  public responses: BibResponse[] = [];
  public gridResponses: BibResponse[] = [];
  public category: BibCategory;
  public categories: BibCategory[];
  public selectedQuestionId: string;
  public oldValueOfDesignation = '';
  public oldValueOfTitle = '';
  public oldValueOfResponses: Array<BibResponse> = [];
  public oldValueOfFactor;
  public oldValueSortNumber: number;
  public oldValueOfDescription = '';
  public isUpdate = false;
  public isDeleteWarning = false;
  public fromUngroupedData: DataSource;
  public allCategories: Libraries[] = [];
  public editingMode = EditingMode.Cell;
  public showLoader = true;
  public responsesIdsToBeDeleted: Array<string> = [];
  public responsesToBeAdded: Array<BibResponse> = [];
  public responsesToBeUpdated: Array<BibResponse> = [];

  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Dt-TreeList-Library'),
      dataField: 'Library',
      dataType: 'string',
      groupIndex: 0,
      visibleIndex: 0,
      allowEditing: false
    },
    {
      caption: this.dataService.res('Dt-Question-Columns-Header-Categories'),
      dataField: 'CategoryDesignation',
      dataType: 'string',
      groupIndex: 0,
      visibleIndex: 1
    },
    {
      caption: this.dataService.res('Dt-Question-Columns-Header-Designation'),
      dataField: 'Designation',
      visibleIndex: 2,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Dt-Question-Columns-Header-Description'),
      dataField: 'Description',
      visibleIndex: 3,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Dt-Question-List-Factor'),
      dataField: 'Factor',
      visibleIndex: 4,
      dataType: 'number',
      alignment: 'left'

    },
    {
      caption: this.dataService.res('Dt-Question-Columns-Header-Antworten'),
      dataField: 'Responses',
      visibleIndex: 5,
      dataType: 'string',
      allowEditing: false,
      calculateFilterExpression: (value, selectedFilterOperations) => {
        if (value) {
          return [this.getResponse, "contains", value];
        }
        else {
          return ['Answers', selectedFilterOperations, null]
        }
      },
      lookup: {
        dataSource: this.gridResponses,
        valueExpr: 'Id',
        displayExpr: 'Designation'

      },
      cellTemplate: this.cellTemplate
    },
    {
      caption: this.dataService.res('Dt-Grid-Sort-Order'),
      dataField: 'SortNr',
      dataType: 'number',
      alignment: 'left',
      visibleIndex: 6
    }
  ];
  public mainQueryColumns: Array<QueryColumn> = [
    this.coreDataService.createQueryColumn('ref:!.ParentId', 'Library'),
    this.coreDataService.createQueryColumn('ref:ParentId', 'Category'),
    this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
    this.coreDataService.createQueryColumn('Id', 'Id'),
    this.coreDataService.createQueryColumn('Title', 'Title'),
    this.coreDataService.createQueryColumn('Description', 'Description'),
    this.coreDataService.createQueryColumn('Designation', 'Designation'),
    this.coreDataService.createQueryColumn('!.Designation', 'CategoryDesignation'),
    this.coreDataService.createQueryColumn('SeqNr', 'SeqNr'),
    this.coreDataService.createQueryColumn('Factor', 'Factor'),
    this.coreDataService.createQueryColumn('SortNr', 'SortNr'),
    this.coreDataService.createQueryColumn(
      "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
      'IsFavorite'
    ),
    this.coreDataService.createQueryColumn(
      "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
      'BoxDetailId'
    )
  ];

  public subQueryColumns: Array<QueryColumn> = [
    this.coreDataService.createQueryColumn('Id', 'Id'),
    this.coreDataService.createQueryColumn('SeqNr', 'SeqNr'),
    this.coreDataService.createQueryColumn('Designation', 'Designation'),
    this.coreDataService.createQueryColumn('Score', 'Score')
  ];

  public cancelButtonOptions = {
    text: this.dataService.res('Dt-Cancel'),
    onClick: () => this.onClosePopup()
  };

  public createButtonOptions = {
    text: this.dataService.res('Dt-Create'),
    useSubmitBehavior: true
  };



  getResponse(rowData) {
    let responseIds = ''
    if (rowData.Responses) {
      rowData.Responses.forEach(response => {
        responseIds = responseIds + (response.Id).toString();
      });
      return responseIds;
    }
    return null;
  }

  public editButtonOptions = {
    text: this.dataService.res('Dt-Finished'),
    useSubmitBehavior: true
  };

  private cellTemplate(container, options) {
    const datatoDisplay = [];
    (options.value ?? []).map((element) => {
      datatoDisplay.push(element.Designation);
    });
    datatoDisplay.forEach((ans)=>{
      let div = document.createElement("div");
      let p = document.createElement("p");
      p.append(ans.toString());
      p.style.margin = '4px 0px 0px 4px';
      p.style.padding = '3px 25px 4px 6px';
      p.style.backgroundColor = '#ddd';
      p.style.borderRadius =  '2px';
      p.style.color = '#333';
      p.style.minWidth = '40px';
      p.style.display ="inline-block";
      p.style.position ="relative";
      p.style.textAlign ="center";
      div.append(p);
      container.append(div);
    })
  }

  public onCellPrepared(e): void {
    if (e.rowType === "group" && e.column.dataField === "CategoryDesignation" && e.columnIndex === 2) {
      const currentCategory = e.data.key;
      const addQuestionContainer = document.createElement("div");
      addQuestionContainer.style.float = 'right';
      const addQuestionButton = document.createElement("img");
      addQuestionButton.setAttribute('src', "assets/images/plus.svg");
      addQuestionButton.style.width = '25px';
      addQuestionButton.style.cursor = 'pointer';
      addQuestionContainer.append(addQuestionButton);

      e.cellElement.append(addQuestionContainer);
      addQuestionButton.addEventListener('click', () => {
        this.allCategories.forEach(category => {
          if (category.LibraryDesignation === currentCategory) {
            this.question.ParentId = category.Id;
          }
        });
        this.addQuestionPopup.instance.show();
      });
    }
  }

  constructor(
    private persistenceService: GeoDsPersistenceService,
    private coreDataService: GeoDsCoreDataService,
    public dataService: DataService,
    private sharedDataService: SharedDataService,
    private appService: AppService,
    public favoritesService: FavoritesService,
    public questionListService: QuestionListService
  ) { }

  ngOnInit(): void {
    this.columnsHeader.push({
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [
        {
          icon: 'edit',
          text: this.dataService.res('Dt-Edit'),
          onClick: (e) => {
            this.openQuestionDetail(e);
          }
        },
        {
          icon: 'trash',
          text: this.dataService.res('Dt-Delete'),
          onClick: (e) => {
            this.openDeleteQuestionDialog(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-inactive',
          text: 'add favorite',
          visible: (e) => !this.isFavoriteVisible(e),
          onClick: (e) => {
            this.addFavorite(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-active',
          text: 'remove favorite',
          visible: (e) => this.isFavoriteVisible(e),
          onClick: (e) => {
            this.deleteFavorite(e);
          }
        },
      ]
    });

    const questionQuery = this.prepareQueryToGetQuestionData();

    this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(questionQuery).pipe(switchMap(questions => {
      return this.dataService.loadListDataSource(ObjectKeys.BIBCATEGORY, "Id").pipe(map(categoriesData => ({ questions, categoriesData })));
    })).subscribe(({ questions, categoriesData }) => {
      this.dataService.cachedCategories = categoriesData.Data;
      this.questions = questions;
      this.questions.forEach((question) => {
        if (question.Responses) {
          question.Responses.forEach(res => {
            if (!this.gridResponses.find(e => e.Designation === res.Designation)) {
              this.gridResponses.push(res)
            }
          });
        }
      });

      this.subscriptions.push(this.dataService.favoriteName$.subscribe((data) => {
        if (this.dataService.favoriteData.get(data)?.length !== 0) {
          this.questionGrid.dxDataGrid.instance.clearFilter();
          this.questionGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', this.dataService.favoriteData.get(data));
        }
        else {
          this.questionGrid.dxDataGrid.instance.clearFilter();
          this.questionGrid.dxDataGrid.instance.refresh();
        }
      }))
      if (localStorage.getItem('questionName')?.length == 0) {
        this.questionGrid.dxDataGrid.instance.clearFilter();
        this.questionGrid.dxDataGrid.instance.refresh();
      } else {
        this.questionGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', localStorage.getItem('questionName'));
      }
      this.showLoader = false;
    }, error => {
      console.error(error);
      this.showLoader = false;
    }));

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((question: BibQuestion) => {
      this.questions.push(question);
    }));

    this.getCategories().subscribe((categories) => {
      this.allCategories = categories;
      this.fromUngroupedData = new DataSource({
        store: new ArrayStore({
          data: categories,
          key: 'Id',
        }),
        group: 'Library',
      });
    });

  }

  public isFavoriteVisible(e) {
    if (e.row?.data.IsFavorite === 1) {
      return true;
    } else {
      return false;
    }
  }

  public getCategories(): Observable<Libraries[]> {
    let columnsLibrary: QueryColumn[] = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Library'),
      this.coreDataService.createQueryColumn('Designation', 'LibraryDesignation')
    ]
    return this.dataService
      .readObjects(ObjectKeys.BIBCATEGORY, columnsLibrary)
  }

  public addFavorite(e): void {
    this.favoritesService.persistFavoriten(GUID.newGUID(), ObjectTypeNames.AudBibQuestion, e.row?.data.Id, e.row?.data.Designation).subscribe((data) => {
      this.questionsUpdateState(e, 'add', data);
    });
  }

  public deleteFavorite(e): void {
    this.favoritesService.deleteFavoriten(e.row?.data.BoxDetailId);
    this.questionsUpdateState(e, 'delete');
  }

  public questionsUpdateState(e, state: string, favoriteData?): void {
    let selectedIndex = this.questions.findIndex((question) => question.Id === e.row?.data.Id);
    if (state === 'add') {
      const clonedItem = { ...e.row?.data, IsFavorite: 1, BoxDetailId: favoriteData.Id };
      this.questions[selectedIndex] = clonedItem;
    } else {
      const clonedItem = { ...e.row?.data, IsFavorite: 0 };
      this.questions[selectedIndex] = clonedItem;
    }
  }

  public prepareQueryToGetQuestionData(opath?: string): QueryObjectsModel {
    const mainQuery: Query = new Query();
    if (opath) {
      mainQuery.OPath = opath
    }
    mainQuery.ObjectType = 'AudBibQuestion';
    mainQuery.Columns = this.mainQueryColumns;
    const subQuery: Query = new Query();
    subQuery.Name = 'Responses';
    subQuery.OPath = 'BibResponses';
    subQuery.Columns = this.subQueryColumns;
    mainQuery.ObjectQueries = [subQuery];
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [mainQuery];
    return queryDocument;
  }

  public readAuditItemSelectedByQuestionId(): Observable<any> {
    const auditItemSelectedQuery: Query = new Query();
    const auditItemSelectedQueryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id')
    ];
    auditItemSelectedQuery.OPath = `IdRefBibQuestion='${this.selectedQuestionId}'`;

    auditItemSelectedQuery.ObjectType = ObjectKeys.AUDITITEM;
    auditItemSelectedQuery.Columns = auditItemSelectedQueryColumns;

    const auditItemSelectedQueryDocument: QueryObjectsModel = new QueryObjectsModel();
    auditItemSelectedQueryDocument.ObjectQueries = [auditItemSelectedQuery];

    return this.coreDataService
      .executeReadObjectsQuery(auditItemSelectedQueryDocument);
  }

  public persistQuestion(): Observable<any> {
    const questionPersistQuery: TargetObjectData = new TargetObjectData();
    questionPersistQuery.ObjectKey = new ObjectKey();
    questionPersistQuery.ObjectKey.ObjectType = ObjectKeys.BIBQUESTION;
    if (this.buttonAction === 'edit') {
      questionPersistQuery.Mode = PersistMode.Update;
      questionPersistQuery.ObjectKey.Id = this.question.Id;
    } else {
      questionPersistQuery.Mode = PersistMode.Insert;
      questionPersistQuery.ObjectKey.Id = this.question.Id;
    }
    const questionColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: this.categoriesList.selectedItem.Id },
      {
        Name: 'Designation',
        Value: this.question.Designation
      },
      { Name: 'Description', Value: this.question.Description },
      { Name: 'Factor', Value: this.question.Factor },
      { Name: 'SortNr', Value: this.question.SortNr }
    ];
    questionPersistQuery.TargetColumns = questionColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = questionPersistQuery;

    return this.persistenceService.executePersistObjectQuery(persistObject);
  }

  public persistResponsesList(maxSeqNr: number, questionId: string): Observable<any> {
    const query: PersistObjectsModel = new PersistObjectsModel();
    let responsesPersistQuery: TargetObjectData[][] = [[]];
    let seqNumber = Number.isInteger(maxSeqNr) ? maxSeqNr : 0;
    this.responsesToBeAdded?.forEach((response) => {
      let responsesInsertObjectData: TargetObjectData = new TargetObjectData();
      responsesInsertObjectData.Mode = PersistMode.Insert;
      responsesInsertObjectData.ObjectKey = new ObjectKey();
      responsesInsertObjectData.ObjectKey.ObjectType =
        ObjectKeys.BIBRESPONSE;
      const employeesColumns: TargetColumnValue[] = [
        { Name: 'ParentId', Value: questionId },
        { Name: 'Designation', Value: response.Designation },
        { Name: 'Score', Value: response.Score },
        { Name: 'SeqNr', Value: (seqNumber + 1).toString() }
      ]
      responsesInsertObjectData.TargetColumns = employeesColumns;
      responsesPersistQuery[0].push(responsesInsertObjectData);
      seqNumber = seqNumber + 1;
    });

    this.responsesToBeUpdated?.forEach((response) => {
      let responsesUpdateObjectData: TargetObjectData = new TargetObjectData();
      responsesUpdateObjectData.Mode = PersistMode.Update;
      responsesUpdateObjectData.ObjectKey = new ObjectKey();
      responsesUpdateObjectData.ObjectKey.Id = response.Id;
      responsesUpdateObjectData.ObjectKey.ObjectType = ObjectKeys.BIBRESPONSE;
      const employeesColumns: TargetColumnValue[] = [
        { Name: 'Designation', Value: response.Designation },
        { Name: 'Score', Value: response.Score }
      ]

      responsesUpdateObjectData.TargetColumns = employeesColumns;
      responsesPersistQuery[0].push(responsesUpdateObjectData);
    });

    this.responsesIdsToBeDeleted?.forEach((id) => {
      const responsesDeleteObjectData: TargetObjectData = new TargetObjectData();
      responsesDeleteObjectData.Mode = PersistMode.Delete;
      responsesDeleteObjectData.ObjectKey = new ObjectKey();
      responsesDeleteObjectData.ObjectKey.Id = id;
      responsesDeleteObjectData.ObjectKey.ObjectType =
        ObjectKeys.BIBRESPONSE;
      responsesPersistQuery[0].push(responsesDeleteObjectData);
    });

    query.Objects = responsesPersistQuery;
    return this.persistenceService.executePersistObjectsQuery(query);
  }

  public onCreateQuestion(event): void {
    event.preventDefault();
    const addQuestionWithResponses$ = this.persistQuestion()
      .pipe(
        switchMap((persistedQuestion) => {
          return this.questionListService.getMaxSeqNr(persistedQuestion.Id)
            .pipe(map(maxSeqNr => ({ maxSeqNr: maxSeqNr ? maxSeqNr[0].maxSeqNr : 0, persistedQuestion: persistedQuestion })))
        }),
        switchMap(({ maxSeqNr, persistedQuestion }) => {
          return this.persistResponsesList(maxSeqNr, persistedQuestion.Id)
            .pipe(map(persistedResponses => ({ persistedResponses: persistedResponses, persistedQuestion: persistedQuestion })))
        }
        ))

    this.subscriptions.push(addQuestionWithResponses$.subscribe(({ persistedResponses, persistedQuestion }) => {
      this.updateGrid({ isUpdated: this.isUpdate, Id: persistedQuestion.Id });
      this.onClosePopup(true);
    }, (error) => {
      this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });
    }))
  }

  public updateGrid(event: { isUpdated: boolean, Id: string }): void {
    if (event.Id) {
      let opath = 'Id=' + "'" + event.Id + "'";
      let questionQuery = this.prepareQueryToGetQuestionData(opath);
      this.coreDataService.executeReadObjectsQuery(questionQuery).
        subscribe((foundQuestion) => {
          let question = foundQuestion[0]
          if (event.isUpdated) {
            let selectedIndex = this.questions.findIndex((question) => question.Id === event.Id);
            this.questions[selectedIndex] = question;
            this.showLoader = false;
          } else {
            this.questions.unshift(question);
            this.showLoader = false;
          }
        })
    }
  }

  public openQuestionDialog(isUpdate?: boolean): void {
    this.isUpdate = isUpdate;
    this.oldValueOfDesignation = this.question.Designation;
    this.oldValueOfDescription = this.question.Description;
    this.oldValueOfResponses = [...this.responses];
    this.oldValueOfTitle = this.question.Title;
    this.oldValueOfFactor = this.question.Factor;
    this.oldValueSortNumber = this.question.SortNr;
    this.addQuestionPopup.instance.show();
  }

  public openQuestionDetail(event): void {
    this.buttonAction = event.column.name;
    this.question = event.row?.data;
    this.responses = event.row?.data.Responses ? event.row?.data.Responses : [];
    this.rowIndex = event.row.dataIndex;
    this.openQuestionDialog(true);
  }

  public openDeleteQuestionDialog(event): void {
    this.selectedQuestionId = event.row?.data.Id;
    this.readAuditItemSelectedByQuestionId().subscribe((data) => {
      if (data.length > 0) {
        this.isDeleteWarning = true;
      }
      this.deleteQuestionPopup.instance.show();
    })
  }

  public deleteQuestion(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.BIBQUESTION, this.selectedQuestionId)
        .subscribe((deletedQuestion) => {
          if (deletedQuestion?.Id) {
            this.questions = this.questions.filter(
              (question) => question.Id != deletedQuestion?.Id
            );
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Delete-Question-Success'),
              type: ToastType.SUCCESS
            });
          } else {
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Error'),
              type: ToastType.ERROR
            });
          }
        }, error => {
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Error'),
            type: ToastType.ERROR
          });
        })
    );
    this.deleteQuestionPopup.instance.hide();
  }

  public onClosePopup(persist?: boolean): void {
    this.responsesIdsToBeDeleted = [];
    this.responsesToBeAdded = [];
    this.responsesToBeUpdated = [];

    if (this.responsesGrid.dxDataGrid.instance.hasEditData()) {
      this.responsesGrid.dxDataGrid.instance.cancelEditData();
    }

    if (!persist) {
      if (this.isUpdate) {
        this.question.Designation = this.oldValueOfDesignation;
        this.question.Description = this.oldValueOfDescription;
        this.responses = [...this.oldValueOfResponses];
        this.question.Title = this.oldValueOfTitle;
        this.question.Factor = this.oldValueOfFactor;
        this.question.SortNr = this.oldValueSortNumber;
        this.isUpdate = false;
      }
    }
    this.addQuestionPopup.instance.hide();
    this.deleteQuestionPopup.instance.hide();
    this.isDeleteWarning = false;
    this.emptyData();
  }

  public addRow(): void {
    this.responsesGrid.addRow();
  }

  public saveRow(event): void {
    if (event.changes[0].type === GridPersistMode.INSERT) {
      this.responsesToBeAdded.push(event.changes[0].data);
    }

    if (event.changes[0].type === GridPersistMode.UPDATE) {
      if (event.changes[0].data.Id) {
        this.responsesToBeUpdated.push(event.changes[0].data);
      } else {
        this.responsesToBeUpdated.map((response) => {
          if (response["__KEY__"] === event.changes[0].data.__KEY__) {
            response = event.changes[0].data;
          }
        })
      }
    }

    if (event.changes[0].type === GridPersistMode.REMOVE) {
      const id = event?.changes[0].key.Id;
      if (id) {
        this.responsesIdsToBeDeleted.push(id)
        this.responsesToBeUpdated = this.responsesToBeUpdated.filter((employee) => {
          return employee.Id != id;
        })
      } else {
        this.responsesToBeAdded = this.responsesToBeAdded.filter((employee) => {
          return employee["__KEY__"] != event?.changes[0].key["__KEY__"];
        })
      }
    }
    this.responsesGrid.refreshGrid();
  }

  public emptyData(): void {
    this.question = new BibQuestion(null);
    this.buttonAction = '';
    this.response = new BibResponse();
    this.responses = [];
  }

  public onHiding(): void {
    this.emptyData();
  }

  public returnDxItemCssClass(className: string): string {
    return className;
  }

  public getResponsesByQuestion(questionId): Observable<any> {
    const responseQuery: Query = new Query();
    const responseQueryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id')
    ];
    responseQuery.ObjectType = ObjectKeys.BIBRESPONSE;
    responseQuery.Columns = responseQueryColumns;
    responseQuery.OPath = `ParentId='${questionId}'`;

    const responseDocument: QueryObjectsModel = new QueryObjectsModel();
    responseDocument.ObjectQueries = [responseQuery];

    return this.coreDataService.executeReadObjectsQuery(responseDocument);
  }

  public onRowUpdated(event) {
    const questionPersistQuery: TargetObjectData = new TargetObjectData();
    questionPersistQuery.ObjectKey = new ObjectKey();
    questionPersistQuery.ObjectKey.ObjectType = ObjectKeys.BIBQUESTION;

    questionPersistQuery.Mode = PersistMode.Update;
    questionPersistQuery.ObjectKey.Id = event.oldData.Id;

    const questionColumns: TargetColumnValue[] = [
      {
        Name: 'Designation',
        Value: event.newData.Designation ? event.newData.Designation : event.oldData.Designation
      },
      {
        Name: 'Title',
        Value: event.newData.Title ? event.newData.Title : event.oldData.Title
      },
      {
        Name: 'Description',
        Value: event.newData.Description ? event.newData.Description : event.oldData.Description
      },
      {
        Name: 'Factor',
        Value: event.newData.Factor ? event.newData.Factor : event.oldData.Factor
      },
      {
        Name: 'SortNr',
        Value: event.newData.SortNr ? event.newData.SortNr : event.oldData.SortNr
      },
    ];
    questionPersistQuery.TargetColumns = questionColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = questionPersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((result) => {
      this.appService.callNotification({
        message: this.dataService.res('Dt-Question-Succees-update'),
        type: ToastType.SUCCESS
      });
    }, () => {
      this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });
    }));

  }

  public onInitialized(e): void {
    e.component.option('syncLookupFilterValues', false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}