import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GeoDsCoreDataService, QueryColumn, QueryObjectsModel, Query } from '@wissenswerft/core/data';
import { Subscription } from 'rxjs';
import { DataService, ObjectKeys } from "../../services/data.service";
@Component({
  selector: 'favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.scss']
})
export class FavoriteListComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  @Output() closebtn: EventEmitter<any> = new EventEmitter();
  constructor(private router: Router,
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService
  ) { }

  public ObjectTypeNames = ObjectTypeNames;

  ngOnInit(): void { }

  public itemClicked(event): void {
    switch (event.itemData.ObjectType) {
      case ObjectTypeNames.Company: {
        this.changeFavouriteStyle(['container'], '1', 'auto');
        this.dataService.prepareCompanyData(event.itemData.ParentId, event.itemData.DisplayText);
        this.dataService.setFavoriteData(event.itemData.ParentId, event.itemData.ParentId);
        localStorage.setItem('companyId', event.itemData.ParentId);
        localStorage.setItem('companyName', event.itemData.DisplayText);
        this.router.navigateByUrl('dashboard');
        this.closeFavorites();
        break;
      }
      case ObjectTypeNames.AudAudit: {
        const auditData = this.prepareQueryToGetAudit(event.itemData.ParentId);
        this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(auditData).subscribe(data => {
          this.dataService.setFavoriteData("audit", data);
          localStorage.setItem('addedAuditId', event.itemData.ParentId);
          localStorage.setItem('libraryId', data[0].LibraryId);
          localStorage.setItem('auditDesignation', data[0].Designation);
          localStorage.setItem('auditCompanyName', data[0].Company);
          localStorage.setItem('auditCompanyId', data[0].CompanyId);
          this.router.navigate(['evaluation'], {
            state: {
              auditId: event.itemData.ParentId,
              companyName: data[0].Company,
              libraryId: data[0].LibraryId,
              auditDesignation: data[0].Designation
            }
          });
        }));
        this.changeFavouriteStyle(['container'], '1', 'auto');
        this.closeFavorites();
        break;
      }
      case ObjectTypeNames.AudBibCategory:
        const categoryName = this.prepareQueryToGetName(event.itemData.ParentId, ObjectKeys.BIBCATEGORY);
        this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(categoryName).subscribe(data => {
          this.dataService.setFavoriteData("category", data[0].Designation);
          localStorage.setItem('categoryName', data[0].Designation);
          this.router.navigateByUrl('categories');
          this.closeFavorites();
        }));
        break;
      case ObjectTypeNames.AudLibrary:
        const libraryName = this.prepareQueryToGetName(event.itemData.ParentId, ObjectKeys.LIBRARY);
        this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(libraryName).subscribe(data => {
          this.dataService.setFavoriteData("library", data[0].Designation);
          localStorage.setItem('libraryName', data[0].Designation);
          this.router.navigateByUrl('libraries');
          this.closeFavorites();
        }));
        break;
      case ObjectTypeNames.AudBibQuestion:
        const questionName = this.prepareQueryToGetName(event.itemData.ParentId, ObjectKeys.BIBQUESTION);
        this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(questionName).subscribe(data => {
          this.dataService.setFavoriteData("question", data[0].Designation);
          localStorage.setItem('questionName', data[0].Designation);
          this.router.navigateByUrl('questions');
          this.closeFavorites();
        }));
        break;
      case ObjectTypeNames.AudBibResponse:
        const responseName = this.prepareQueryToGetName(event.itemData.ParentId, ObjectKeys.BIBRESPONSE);
        this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(responseName).subscribe(data => {
          this.dataService.setFavoriteData("response", data[0].Designation);
          localStorage.setItem('responseName', data[0].Designation);
          this.router.navigateByUrl('responses');
          this.closeFavorites();
        }));
        break;
      case ObjectTypeNames.AudBibMeasure:
        const measureName = this.prepareQueryToGetMeasureName(event.itemData.ParentId, ObjectKeys.BIBMEAUSRE);
        this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(measureName).subscribe(data => {
          this.dataService.setFavoriteData("measure", data[0].Title);

          localStorage.setItem('measureName', data[0].Title);
          this.router.navigateByUrl('measures');
          this.closeFavorites();
        }));
        break;
      default: {
        this.router.navigateByUrl('');
        this.closeFavorites();
      }
    }
  }

  public prepareQueryToGetAudit(id: string): QueryObjectsModel {
    const query: Query = new Query();
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation'),
      this.coreDataService.createQueryColumn('IdRefLibrary', 'LibraryId'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Company'),
      this.coreDataService.createQueryColumn('ParentId', 'CompanyId')
    ];
    query.OPath = `Id='${id}'`
    query.ObjectType = ObjectKeys.AUDIT;
    query.Columns = queryColumns;
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [query];
    return queryDocument;
  }

  public prepareQueryToGetName(id: string, objectKey: string): QueryObjectsModel {
    const query: Query = new Query();
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Designation', 'Designation')
    ];
    query.OPath = `Id='${id}'`
    query.ObjectType = objectKey;
    query.Columns = queryColumns;
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [query];
    return queryDocument;
  }
  public prepareQueryToGetMeasureName(id: string, objectKey: string): QueryObjectsModel {
    const query: Query = new Query();
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Title', 'Title')
    ];
    query.OPath = `Id='${id}'`
    query.ObjectType = objectKey;
    query.Columns = queryColumns;
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [query];
    return queryDocument;
  }

  public openFavorites(): void {
    document.getElementById('favouritesContainer').style.width = '400px';
    this.changeFavouriteStyle(['container'], '0.2', 'auto');
  }

  public closeFavorites(): void {
    document.getElementById('closeFavoritebtn').style.visibility = 'hidden';
    document.getElementById('favouritesContainer').style.width = '0';
    this.changeFavouriteStyle(['container'], '1', 'auto');
    this.closebtn.emit();
  }

  public changeFavouriteStyle(listIds: Array<string>, opacity: string, pointerEvents: string) {
    listIds.forEach((ids) => {
      document.getElementById(ids).style.visibility = 'true';
      document.getElementById(ids).style.opacity = opacity;
      document.getElementById(ids).style.pointerEvents = pointerEvents;
    });
  }
}

export enum ObjectTypeNames {
  Company = 111000100,
  AudAudit = 330001159,
  AudBibCategory = 330001121,
  AudLibrary = 330001125,
  AudBibQuestion = 330001123,
  AudBibResponse = 330001124,
  AudBibMeasure = 330001122
}