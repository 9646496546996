import { Injectable } from '@angular/core';
import {
  GeoDsCoreDataService,
  GeoDsPersistenceService,
  ReadListDataSourceModel
} from '@wissenswerft/core/data';

import {
  ObjectKey,
  PersistMode,
  PersistObjectModel,
  Query,
  QueryColumn,
  QueryObjectsModel,
  TargetColumnValue,
  TargetObjectData
} from '@wissenswerft/core/data';
import { ResourceManager } from '@wissenswerft/core/resources';
import {
  BibCategory,
  BibQuestion,
  Library,
  BibResponse
} from '@wissenswerft/organizational-structure';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  private readonly objectSpaceName = 'ABC.Applications.KAOS.Data';
  public cachedCategories: BibCategory[];
  public cachedQuestions: BibQuestion[];
  public cachedResponses: BibResponse[];
  public cachedStatus = [];
  public cachedLibrary: Library[];
  public cachedCompaniesTitles = [];
  public res = ResourceManager.getResources('dt');
  public companyData: Map<string, string> = new Map<string, string>();
  public companyName = new Subject<string>();
  public companyName$ = this.companyName.asObservable();
  public favoriteData: Map<string, string> = new Map<string, string>();
  public favoriteName = new Subject<string>();
  public favoriteName$ = this.favoriteName.asObservable();
  constructor(
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService
  ) { }

  public loadListDataSource(
    objectType: string,
    columnName: string
  ): Observable<any> {
    const readListDataSourceQuery = new ReadListDataSourceModel();
    const objectKey = new ObjectKey();
    objectKey.ObjectType = objectType;
    readListDataSourceQuery.ObjectKey = objectKey;
    readListDataSourceQuery.OutputMode = 0;
    readListDataSourceQuery.ColumnName = columnName;
    return this.coreDataService.executeReadListDataSource(
      readListDataSourceQuery
    );
  }

  public readObjects(
    objectType: string,
    columns: QueryColumn[],
    OPath?: string
  ): Observable<any> {
    const readObjectQuery: QueryObjectsModel = new QueryObjectsModel();
    const mainQuery: Query = new Query();
    mainQuery.ObjectType = objectType;
    mainQuery.Columns = columns;
    mainQuery.OPath = OPath;
    if (OPath) {
      mainQuery.OPath = OPath;
    }
    readObjectQuery.ObjectQueries = [mainQuery];
    return this.coreDataService.executeReadObjectsQuery(readObjectQuery);
  }

  public persistObject(
    mode: PersistMode,
    objectKey: ObjectKeys,
    columns?: TargetColumnValue[],
    id?: string
  ): Observable<ArrayBuffer> {
    const persistQuery: PersistObjectModel = new PersistObjectModel();
    persistQuery.ObjectSpaceName = this.objectSpaceName;
    const targetObject: TargetObjectData = new TargetObjectData();
    targetObject.Mode = mode;
    targetObject.ObjectKey = new ObjectKey();
    if (mode === PersistMode.Update) {
      targetObject.ObjectKey.Id = id;
    }
    targetObject.ObjectKey.ObjectType = objectKey;
    targetObject.TargetColumns = columns;
    persistQuery.Object = targetObject;
    return this.persistenceService.executePersistObjectQuery(persistQuery);
  }

  public deleteObject(objectKey: ObjectKeys, id: string): Observable<any> {
    const persistQuery: PersistObjectModel = new PersistObjectModel();
    persistQuery.ObjectSpaceName = this.objectSpaceName;
    const targetObject: TargetObjectData = new TargetObjectData();
    targetObject.Mode = PersistMode.Delete;
    targetObject.ObjectKey = new ObjectKey();
    targetObject.ObjectKey.Id = id;
    targetObject.ObjectKey.ObjectType = objectKey;
    persistQuery.Object = targetObject;
    return this.persistenceService.executePersistObjectQuery(persistQuery);
  }
  public prepareCompanyData(id, companyName) {
    this.companyData.set("id", id);
    this.companyData.set("companyName", companyName);
    this.companyName.next(companyName);
  }
  public setFavoriteData(favoriteName: string, favoriteData: string) {
    this.favoriteData.set(favoriteName, favoriteData);
    this.favoriteName.next(favoriteName);
  }
}

/**
 * These parameters are required for persistence requests
 * @param AUDIT  AuditDate, Designation, ParentId (Address id)
 * @param ADDRESS  Name, Status, MatchCode
 * @param AUDITITEM  Score
 * @param BIBCATEGORY  Designation
 * @param BIBMEAUSRE
 * @param BIBQUESTION  Designation
 * @param BIBRESPONSE  Designation
 * @param LIBRARY  Designation
 * @param MEAUSRE  AuditDate, Designation
 * @param VARIANT  Designation
 * @param VARIANTITEM
 */
export enum ObjectKeys {
  AUDIT = 'AudAudit',
  ADDRESS = 'Address',
  AUDITITEM = 'AudAuditItem',
  BIBCATEGORY = 'AudBibCategory',
  BIBMEAUSRE = 'AudBibMeasure',
  BIBQUESTION = 'AudBibQuestion',
  BIBRESPONSE = 'AudBibResponse',
  LIBRARY = 'AudLibrary',
  MEAUSRE = 'AudMeasure',
  VARIANT = 'AudVariant',
  Person = 'Person',
  VARIANTITEM = 'AudVariantItem'
}
