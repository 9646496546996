import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, LoginComponent } from '@wissenswerft/core/authentication';
import { AppComponent } from './app.component';
import { AuditComponent } from './core/audit/audit.component';
import { CategoryListComponent } from './core/category-list/category-list.component';
import { CompanyListComponent } from './core/company-list/company-list.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { EvaluationComponent } from './core/evaluation/evaluation.component';
import { LibraryListComponent } from './core/library-list/library-list.component';
import { CustomerHistoryComponent } from './core/customer-history/customer-history.component';
import { MeasureListComponent } from './core/measure-list/measure-list.component';
import { QuestionListComponent } from './core/question-list/question-list.component';
import { ResponseListComponent } from './core/response-list/response-list.component';
import { FavoriteListComponent } from './core/favorite-list/favorite-list.component';
import { HomeComponent } from './core/home/home.component';

FavoriteListComponent
const routes: Routes = [
  {
    path: 'app',
    component: AppComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'home',
    component: HomeComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/home/home.module').then(module => module.HomeModule)
  },
  {
    path: 'customer-history',
    component: CustomerHistoryComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-history/customer-history.module').then(module => module.CustomerHistoryModule)
  },
  {
    path: 'qcm', canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/qcm/qcm.module').then(module => module.QcmModule)
  },
  {
    path: 'favorites', component: FavoriteListComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/favorite-list/favorite-list.module').then(module => module.FavoriteListModule)
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent, canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./core/dashboard/dashboard.module').then(
        (module) => module.DashboardModule
      ),
  },
  {
    path: 'evaluation',
    component: EvaluationComponent, canActivate: [AuthenticationGuard],
    data: { auditId: "", companyName: "", libraryId: "" },
    loadChildren: () =>
      import('./core/evaluation/evaluation.module').then(
        (module) => module.EvaluationModule
      ),
  },
  {
    path: 'questions',
    component: QuestionListComponent, canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./core/question-list/question-list.module').then(
        (module) => module.QuestionListModule
      ),
  },
  {
    path: 'companies',
    component: CompanyListComponent, canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./core/company-list/company-list.module').then(
        (module) => module.CompanyListModule
      ),
  },
  {
    path: 'audits',
    component: AuditComponent,
    data: { companyName: "", companyId: "" },
    loadChildren: () =>
      import('./core/audit/audit.module').then((module) => module.AuditModule),
  },
  {
    path: 'responses',
    component: ResponseListComponent,
    loadChildren: () =>
      import('./core/response-list/response-list.module').then((module) => module.ResponseListModule),
  },
  {
    path: 'libraries',
    component: LibraryListComponent,
    loadChildren: () =>
      import('./core/library-list/library-list.module').then((module) => module.LibraryListModule),
  },
  {
    path: 'measures',
    component: MeasureListComponent,
    loadChildren: () =>
      import('./core/measure-list/measure-list.module').then((module) => module.MeasureListModule),
  },
  {
    path: 'categories',
    component: CategoryListComponent,
    loadChildren: () =>
      import('./core/category-list/category-list.module').then((module) => module.CategoryListModule),
  },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthenticationGuard]
})
export class AppRoutingModule { }
