import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {
  DxDrawerModule,
  DxTreeViewModule,
  DxToolbarModule,
  DxTooltipModule,
  DxScrollViewModule,
  DxSwitchModule,
  DxContextMenuModule
} from 'devextreme-angular';
import {
  ScreenService,
  SharedDataService
} from '@wissenswerft/organizational-structure';

import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxNumberBoxModule
} from 'devextreme-angular';
import { DxiSeriesModule } from 'devextreme-angular/ui/nested';

import { CoreAuthenticationModule } from '@wissenswerft/core/authentication';
import {
  BACKEND_SYSTEM,
  CoreConfigService,
  CoreConfigurationModule
} from '@wissenswerft/core/configuration';
import { WwLibraryModule } from '@wissenswerft/ww-library';
import { FlexLayoutModule } from '@angular/flex-layout';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { FavoriteListComponent } from './core/favorite-list/favorite-list.component';
import { FavoriteListModule } from './core/favorite-list/favorite-list.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    DxTooltipModule,
    DxToolbarModule,
    DxDrawerModule,
    DxTreeViewModule,
    DxContextMenuModule,
    DxSwitchModule,
    DxScrollViewModule,
    FlexLayoutModule,
    WwLibraryModule,
    CoreAuthenticationModule,
    CoreConfigurationModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    DxChartModule,
    DxiSeriesModule,
    DxDataGridModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatIconModule,
    FavoriteListModule
  ],
  providers: [
    ScreenService,
    CoreConfigService,
    SharedDataService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigurationFactory,
      deps: [CoreConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function ConfigurationFactory(globalConfig: CoreConfigService) {
  return () => {
    return new Promise((resolve) => {
      globalConfig
        .loadConfiguration(environment.API_URL, BACKEND_SYSTEM.GEODS)
        .then((config) => {
          resolve(config);
        });
    });
  };
}
