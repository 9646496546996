import { Injectable } from '@angular/core';
import { BibCategory, BibMeasure } from '@wissenswerft/organizational-structure';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { GeoDsCoreDataService } from '@wissenswerft/core/data';
import { ObjectKeys, DataService } from '../../services/data.service';
import {
    Query,
    QueryColumn,
    QueryColumnSortOrder,
    QueryObjectsModel
} from 'libs/core/data/src/lib/models/query.model';
import { ChartData } from '../qcm/result/result.component';
@Injectable({
    providedIn: 'root'
})
export class EvaluationService {
    public categories: BibCategory[];
    public cachedCategories: Map<any, any> = new Map<any, any>();
    private favoriteData = [];
    public cachedCategoriesTitles: Array<string> = new Array<string>();
    public selectedCategoryObject: any;

    public selectedCategory: string;
    private auditId: string;
    constructor(
        private coreDataService: GeoDsCoreDataService,
        public dataService: DataService
    ) { }

    public prepareQueryToGetAuditItemsCategories(): Observable<any> {
        let auditId = history.state.auditId
            ? history.state.auditId
            : localStorage.getItem('addedAuditId');

        this.dataService.favoriteName$.subscribe((data) => {
            this.favoriteData = Array.from(this.dataService.favoriteData.get(data));
            auditId = this.favoriteData[0]?.companyId;
        })
        const auditItemCategory: Query = new Query();
        const auditItemCategoryColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn(
                'Designation',
                'Designation'
            ),
            this.coreDataService.createQueryColumn('Score', 'Score'),
            this.coreDataService.createQueryColumn('MaxScore', 'MaxScore'),
            this.coreDataService.createQueryColumn('SortNr', 'SortNr', QueryColumnSortOrder.Ascending),
            this.coreDataService.createQueryColumn('SysDateInsert', 'InsertedDate'),
            this.coreDataService.createQueryColumn('SysDateUpdate', 'UpdatedDate'),
            this.coreDataService.createQueryColumn('SysUserNameInsert', 'SysUserNameInsert'),
            this.coreDataService.createQueryColumn('SysUserNameUpdate', 'SysUserNameUpdate'),
            this.coreDataService.createQueryColumn('Percentage', 'Percentage'),
            this.coreDataService.createQueryColumn(
                'IdRefBibCategory',
                'IdRefBibCategory'
            )
        ];
        auditItemCategory.OPath = `ParentId='${auditId}' And Type LIKE 'Category'`;
        auditItemCategory.ObjectType = ObjectKeys.AUDITITEM;
        auditItemCategory.Columns = auditItemCategoryColumns;

        const auditItemQuestion: Query = new Query();
        auditItemQuestion.Name = 'Questions';
        auditItemQuestion.OPath = 'SubAuditItems';
        const auditItemQuestionColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('Title', 'Title'),
            this.coreDataService.createQueryColumn(
                'Designation',
                'Designation'
            ),
            this.coreDataService.createQueryColumn('Score', 'Score'),
            this.coreDataService.createQueryColumn('BibQuestion.Factor', 'Factor'),
            this.coreDataService.createQueryColumn('MaxScore', 'MaxScore'),
            this.coreDataService.createQueryColumn('SortNr', 'SortNr', QueryColumnSortOrder.Ascending),
            this.coreDataService.createQueryColumn('SysDateInsert', 'InsertedDate'),
            this.coreDataService.createQueryColumn('SysDateUpdate', 'UpdatedDate'),
            this.coreDataService.createQueryColumn('SysUserNameInsert', 'SysUserNameInsert'),
            this.coreDataService.createQueryColumn('SysUserNameUpdate', 'SysUserNameUpdate'),
            this.coreDataService.createQueryColumn('Percentage', 'Percentage'),
            this.coreDataService.createQueryColumn(
                'IdRefBibQuestion',
                'IdRefBibQuestion'
            ),
            this.coreDataService.createQueryColumn(
                'ref:IdRefBibQuestion',
                'description'
            )
        ];
        auditItemQuestion.Columns = auditItemQuestionColumns;

        const auditItemResponse: Query = new Query();
        auditItemResponse.Name = 'SelectedResponse';
        auditItemResponse.OPath = 'SubAuditItems';
        const auditItemResponseColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn(
                'Designation',
                'Designation'
            ),
            this.coreDataService.createQueryColumn('Score', 'Score'),
            this.coreDataService.createQueryColumn(
                'IdRefBibResponse',
                'IdRefBibResponse'
            )
        ];
        auditItemResponse.Columns = auditItemResponseColumns;

        const auditMeasure: Query = new Query();
        auditMeasure.Name = 'Measures';
        auditMeasure.OPath = 'Measures';
        const auditMeasureColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('Designation', 'Title'),
            this.coreDataService.createQueryColumn('Goal', 'Goal'),
            this.coreDataService.createQueryColumn('IdRefAuditItem', 'ResponseId')
        ];
        auditMeasure.Columns = auditMeasureColumns;

        const Measure: Query = new Query();
        Measure.Name = 'Measures';
        Measure.OPath = 'BibMeasures';
        const MeasureColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('Title', 'Title'),
            this.coreDataService.createQueryColumn('Goal', 'Goal'),
            this.coreDataService.createQueryColumn('ParentId', 'ResponseId'),
            this.coreDataService.createQueryColumn('SortNr', 'SortNr', QueryColumnSortOrder.Ascending)
        ];
        Measure.Columns = MeasureColumns;

        const auditItemQuestionAllResponses: Query = new Query();
        auditItemQuestionAllResponses.Name = 'Responses';
        auditItemQuestionAllResponses.OPath = 'BibQuestion.BibResponses';
        const auditItemQuestionAllResponsesColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn(
                'Designation',
                'Designation'
            ),
            this.coreDataService.createQueryColumn('Score', 'Score'),
            this.coreDataService.createQueryColumn('SortNr', 'SortNr', QueryColumnSortOrder.Ascending)
        ];
        auditItemQuestionAllResponses.Columns = auditItemQuestionAllResponsesColumns;

        auditItemQuestionAllResponses.ObjectQueries = [Measure]

        auditItemResponse.ObjectQueries = [auditMeasure]

        auditItemQuestion.ObjectQueries = [auditItemResponse, auditItemQuestionAllResponses];

        auditItemCategory.ObjectQueries = [auditItemQuestion];

        const queryDocument: QueryObjectsModel = new QueryObjectsModel();
        queryDocument.ObjectQueries = [auditItemCategory];

        return this.coreDataService.executeReadObjectsQuery(queryDocument);
    }
}
