import { Injectable } from '@angular/core';
import { QueryColumn, Query, QueryObjectsModel, GeoDsCoreDataService } from '@wissenswerft/core/data';
import { Observable } from 'rxjs';
import { ObjectKeys } from '../../services/data.service';

export enum TypeOfAnswer {
  SelectBox = 'SelectBox',
  TagBox = 'TagBox',
  Multiple = 'Multiple'
}

export enum Category {
  Settings = `Settings, Readiness for change, Digitization strategy`,
  TechnicalRequirements = `Technical requirements`,
  PersonnelRequirements = `Personnel requirements`,
  DigitalTechnologiesAndServices = `Use of digital technologies and services`,
  SoftwareSolutionsInTheCompany = `Use of software solutions in the company`,
  DigitizationEffectsAndProspects = `Digitization effects and prospects`,
  DigitalCapabilitiesAndImpactOnTheEnterprise = `Digital capabilities and impact on the enterprise`,
  ClassifyingInformation = 'Classifying information'
}
export class Answer {
  Designation: string;
}

const answer: Answer = {
  Designation: '',
};
@Injectable({
  providedIn: 'root'
})

export class QuestionListService {

  constructor(
    private coreDataService: GeoDsCoreDataService
  ) { }

  public questionType = [
    { label: TypeOfAnswer.TagBox, value: TypeOfAnswer.TagBox },
    { label: TypeOfAnswer.Multiple, value: TypeOfAnswer.Multiple },
    { label: TypeOfAnswer.SelectBox, value: TypeOfAnswer.SelectBox }
  ];

  public questionTypesPopUp = [TypeOfAnswer.TagBox, TypeOfAnswer.Multiple, TypeOfAnswer.SelectBox];

  getTypeOfAnswer() {
    return TypeOfAnswer;
  }

  getAnswer() {
    return answer;
  }

  public getMaxSeqNr(questionId: string): Observable<any> {
    const query: Query = new Query();
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Max(BibResponses.SeqNr)', "maxSeqNr")
    ];
    query.ObjectType = ObjectKeys.BIBQUESTION;
    query.Columns = queryColumns;
    query.OPath = `Id = '${questionId}'`;
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [query];
    return this.coreDataService.executeReadObjectsQuery(queryDocument);
  }

}
