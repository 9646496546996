import { Component, OnInit, ViewChild } from '@angular/core';
import { BibMeasure } from '@wissenswerft/organizational-structure';
import { EditingMode, GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Observable, Subscription } from 'rxjs';
import {
  CompareHelper,
  GeoDsCoreDataService,
  GeoDsPersistenceService,
  GUID,
  ObjectKey,
  PersistMode,
  PersistObjectModel,
  Query,
  QueryColumn,
  QueryColumnSortOrder,
  TargetColumnValue,
  TargetObjectData
} from '@wissenswerft/core/data';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';

import { Column } from 'devextreme/ui/data_grid';
import { DxPopupComponent, DxSelectBoxComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { FavoritesService } from 'libs/favorites/src/lib/favorites.service';
import { ObjectTypeNames } from '../favorite-list/favorite-list.component';
import { Category, Question } from '../response-list/response-list.model';

export interface Response {
  Id: string;
  Question: string;
  ResponseDesignation: string;
}
@Component({
  selector: 'digitalization-tool-measure-list',
  templateUrl: './measure-list.component.html',
  styleUrls: ['./measure-list.component.scss']
})
export class MeasureListComponent implements OnInit {
  @ViewChild('measuresGrid') measuresGrid: GridComponent;
  @ViewChild('addMeasurePopup') addMeasurePopup: DxPopupComponent;
  @ViewChild('categoryList') categoryList: DxSelectBoxComponent;
  @ViewChild('questionsList') questionsList: DxSelectBoxComponent;
  @ViewChild('responseList') responseList: DxSelectBoxComponent;
  @ViewChild('deleteMeasurePopup') deleteMeasurePopup: DxPopupComponent;

  compareHelper = CompareHelper

  public measure: BibMeasure = new BibMeasure(null);
  public measures: BibMeasure[] = [];
  public selectedMeasureCategory = '';
  public selectedMeasureQuestion = '';
  private subscriptions: Subscription[] = [];
  public selectedMeasureId: string;
  public buttonAction = '';
  public rowIndex: number;
  public columns: QueryColumn[] = [];
  public oldValueOfTitle = '';
  public oldValueOfCategory = '';
  public oldValueOfQuestion = '';
  public oldValueOfGoal = '';
  public oldValueOfSortNr: number;
  public isUpdate = false;
  public fromUngroupedDataResponse: DataSource;
  public fromUngroupedDataCategory: DataSource;
  public fromUngroupedDataQuestion: DataSource;
  public allCategories: Category[] = [];
  public allQuestions: Question[] = [];
  public allResponses: Response[] = [];
  public showLoader = true;
  public editingMode = EditingMode.Cell;

  public cancelButtonOptions = {
    text: this.dataService.res('Dt-Cancel'),
    onClick: () => this.onClosePopup()
  };

  public editButtonOptions = {
    text: this.dataService.res('Dt-Finished'),
    useSubmitBehavior: true
  };

  public createButtonOptions = {
    text: this.dataService.res('Dt-Create'),
    useSubmitBehavior: true
  };

  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Dt-Measure-Columns-Header-Response'),
      dataField: 'Response',
      dataType: 'string',
      groupIndex: 1,
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Dt-Measure-Columns-Header-Question'),
      dataField: 'Question',
      dataType: 'string',
      groupIndex: 1,
      visibleIndex: 1
    },
    {
      caption: this.dataService.res('Dt-Measure-Columns-Header-Title'),
      dataField: 'Designation',
      dataType: 'string',
      visibleIndex: 2
    },
    {
      caption: this.dataService.res('Dt-Measure-Columns-Header-Goal'),
      dataField: 'Goal',
      dataType: 'string',
      visibleIndex: 3
    },
    {
      caption: this.dataService.res('Dt-Grid-Sort-Order'),
      dataField: 'SortNr',
      dataType: 'number',
      alignment: 'left',
      visibleIndex: 4
    }
  ];

  constructor(
    private persistenceService: GeoDsPersistenceService,
    private coreDataService: GeoDsCoreDataService,
    public dataService: DataService,
    private appService: AppService,
    public favoritesService: FavoritesService
  ) { }

  ngOnInit(): void {
    this.columnsHeader.push({
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [
        {
          icon: 'edit',
          text: this.dataService.res('Dt-Edit'),
          onClick: (e) => {
            this.openResponseDetail(e);
          }
        },
        {
          icon: 'trash',
          text: this.dataService.res('Dt-Delete'),
          onClick: (e) => {
            this.openDeleteMeasureDialog(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-inactive',
          text: 'add favorite',
          visible: (e) => !this.isFavoriteVisible(e),
          onClick: (e) => {
            this.addFavorite(e);
          }
        },
        {
          icon: 'like',
          cssClass: 'favorite-active',
          text: 'remove favorite',
          visible: (e) => this.isFavoriteVisible(e),
          onClick: (e) => {
            this.deleteFavorite(e);
          }
        }
      ]
    });
    this.subscriptions.push(this.getResponses().subscribe(data => {
      this.allResponses = data;
    }))

    this.columns.push(
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Response'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('ref:!.ParentId', 'Question'),
      this.coreDataService.createQueryColumn('SeqNr', 'SeqNr'),
      this.coreDataService.createQueryColumn('SortNr', 'SortNr', QueryColumnSortOrder.Ascending),
      this.coreDataService.createQueryColumn('Title', 'Designation'),
      this.coreDataService.createQueryColumn('!.!.ParentId', 'CategoryId'),
      this.coreDataService.createQueryColumn('!.ParentId', 'QuestionId'),

      this.coreDataService.createQueryColumn('Goal', 'Goal'),
      this.coreDataService.createQueryColumn(
        "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
        'IsFavorite'
      ),
      this.coreDataService.createQueryColumn(
        "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
        'BoxDetailId'
      )
    );

    this.getCategories().subscribe((categories) => {
      this.allCategories = categories;
      this.fromUngroupedDataCategory = new DataSource({
        store: new ArrayStore({
          data: categories,
          key: 'Id',
        }),
        group: 'Library',
      });
    });

    this.getQuestions().subscribe((questions) => {
      this.allQuestions = questions;
      this.fromUngroupedDataQuestion = new DataSource({
        store: new ArrayStore({
          data: questions,
          key: 'Id',
        }),
        group: 'Category',
      });
    });

    this.getResponses().subscribe((responses) => {
      this.fromUngroupedDataResponse = new DataSource({
        store: new ArrayStore({
          data: responses,
          key: 'Id',
        }),
        group: 'Question',
      });
    });

    this.getMeasures(this.columns);
  }

  public getQuestions(opath?): Observable<Question[]> {

    const questionSelectedQuery: Query = new Query();

    let columnsQuestion: QueryColumn[] = [
      this.coreDataService.createQueryColumn("Concat(ref:ParentId,'/', Designation)", 'FullPath'),
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Category'),
      this.coreDataService.createQueryColumn('Designation', 'QuestionDesignation'),
      this.coreDataService.createQueryColumn('ParentId', 'CategoryId')
    ]

    return this.dataService
      .readObjects(ObjectKeys.BIBQUESTION, columnsQuestion, opath)
  }

  public getCategories(): Observable<Category[]> {
    let columnsCategory: QueryColumn[] = [
      this.coreDataService.createQueryColumn("Concat(ref:ParentId,'/', Designation)", 'FullPath'),
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Library'),
      this.coreDataService.createQueryColumn('Designation', 'CategoryDesignation')
    ]
    return this.dataService
      .readObjects(ObjectKeys.BIBCATEGORY, columnsCategory);
  }

  public getResponses(opath?): Observable<Response[]> {
    let columnsQuestion: QueryColumn[] = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ref:ParentId', 'Question'),
      this.coreDataService.createQueryColumn('Designation', 'ResponsesDesignation')
    ]
    return this.dataService
      .readObjects(ObjectKeys.BIBRESPONSE, columnsQuestion, opath);
  }

  public categorySelect(event): void {
    let opath = "";
    if (this.categoryList?.selectedItem.Id) {
      opath = `ParentId = '${this.categoryList?.selectedItem.Id}'`;
    }
    this.subscriptions.push(
      this.getQuestions(opath).subscribe((questions) => {
        this.fromUngroupedDataQuestion = new DataSource({
          store: new ArrayStore({
            data: questions,
            key: 'Id',
          }),
          group: 'Category',
        });
      })
    )
  }

  public questionSelect(event): void {
    let opath = "";
    if (this.questionsList?.selectedItem.Id) {
      opath = `ParentId = '${this.questionsList?.selectedItem.Id}'`;
    }
    this.subscriptions.push(
      this.getResponses(opath).subscribe((responses) => {
        const isEmpty = CompareHelper.isEmptyObject(responses)
        if (isEmpty) {
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Info'),
            type: ToastType.INFO
          });
        }
        this.fromUngroupedDataResponse = new DataSource({
          store: new ArrayStore({
            data: (!isEmpty) ? responses : this.allResponses,
            key: 'Id',
          }),
          group: 'Question',
        });
      })
    )
  }
  public isFavoriteVisible(e) {
    if (e.row?.data.IsFavorite === 1) {
      return true;
    } else {
      return false;
    }
  }

  public addFavorite(e): void {
    this.favoritesService.persistFavoriten(GUID.newGUID(), ObjectTypeNames.AudBibMeasure, e.row?.data.Id, e.row?.data.Designation).subscribe((data) => {
      this.measuresUpdateState(e, 'add', data);
    });
  }

  public deleteFavorite(e): void {
    this.favoritesService.deleteFavoriten(e.row?.data.BoxDetailId);
    this.measuresUpdateState(e, 'delete');
  }

  public measuresUpdateState(e, state: string, favoriteData?): void {
    let selectedIndex = this.measures.findIndex((measure) => measure.Id === e.row?.data.Id);
    if (state === 'add') {
      const clonedItem = { ...e.row?.data, IsFavorite: 1, BoxDetailId: favoriteData.Id };
      this.measures[selectedIndex] = clonedItem;
    } else {
      const clonedItem = { ...e.row?.data, IsFavorite: 0 };
      this.measures[selectedIndex] = clonedItem;
    }
  }

  public getMeasures(columns: QueryColumn[], persist?) {
    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.BIBMEAUSRE, columns)
        .subscribe((measuresData) => {
          measuresData?.forEach((data, index) => {
            let response = data.Response;
            let question = data.Question;
            response = response.replace(question + ' /', '');
            measuresData[index].Response = response;
          })
          this.measures = measuresData;
          this.subscriptions.push(this.dataService.favoriteName$.subscribe((data) => {
            if (this.dataService.favoriteData.get(data)?.length !== 0) {
              this.measuresGrid.dxDataGrid.instance.clearFilter();
              this.measuresGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', this.dataService.favoriteData.get(data));
            }
            else {
              this.measuresGrid.dxDataGrid.instance.clearFilter();
              this.measuresGrid.dxDataGrid.instance.refresh();
            }
          }))
          if (localStorage.getItem('measureName')?.length == 0) {
            this.measuresGrid.dxDataGrid.instance.clearFilter();
            this.measuresGrid.dxDataGrid.instance.refresh();
          } else {
            this.measuresGrid.dxDataGrid.instance.columnOption('Designation', 'filterValue', localStorage.getItem('measureName'));
          }
          this.showLoader = false;
          if (persist) {
            this.onClosePopup(true);
          }
        }, error => {
          console.error(error);
          this.showLoader = false;
        })
    );
  }

  public getMeasureById(columns: QueryColumn[], id) {
    let opath = 'Id=' + "'" + id + "'";
    this.subscriptions.push(
      this.dataService
        .readObjects(ObjectKeys.BIBMEAUSRE, columns, opath)
        .subscribe((measuresData) => {
          if (this.buttonAction == 'edit') {
            let selectedIndex = this.measures.findIndex((measure) => measure.Id === id);
            const clonedItem = { ...measuresData[0], Response: measuresData[0].Response.replace(measuresData[0].Question + ' /', '') };
            this.measures[selectedIndex] = clonedItem;
            this.appService.callNotification({
              message: this.dataService.res('Dt-Measure-Succees-update'),
              type: ToastType.SUCCESS
            });
          } else {
            measuresData[0].Response = measuresData[0].Response.replace(measuresData[0].Question + ' /', '');
            this.measures.push(measuresData[0]);
            this.appService.callNotification({
              message: this.dataService.res('Dt-Measure-Succees-insert'),
              type: ToastType.SUCCESS
            });
          }
          this.fromUngroupedDataQuestion = new DataSource({
            store: new ArrayStore({
              data: this.allQuestions,
              key: 'Id',
            }),
            group: 'Category',
          });
          this.fromUngroupedDataResponse = new DataSource({
            store: new ArrayStore({
              data: this.allResponses,
              key: 'Id',
            }),
            group: 'Question',
          });
          this.onClosePopup(true);
        })
    );
  }

  public persistMeasure() {
    const measurePersistQuery: TargetObjectData = new TargetObjectData();
    measurePersistQuery.ObjectKey = new ObjectKey();
    measurePersistQuery.ObjectKey.ObjectType = ObjectKeys.BIBMEAUSRE;
    if (this.buttonAction == 'edit') {

      measurePersistQuery.Mode = PersistMode.Update;
      measurePersistQuery.ObjectKey.Id = this.measure.Id;
    } else {
      measurePersistQuery.Mode = PersistMode.Insert;
    }

    const measureColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: this.responseList.selectedItem.Id },
      { Name: 'Title', Value: this.measure.Designation },
      { Name: 'Goal', Value: this.measure.Goal },
      { Name: 'SortNr', Value: this.measure.SortNr }
    ];
    measurePersistQuery.TargetColumns = measureColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = measurePersistQuery;
    this.subscriptions.push(
      this.persistenceService
        .executePersistObjectQuery(persistObject)
        .subscribe(
          (data) => {
            this.getMeasureById(this.columns, data.Id);
          },
          () => {
            this.appService.callNotification({
              message: this.dataService.res('Dt-Error-Occured'),
              type: ToastType.ERROR
            });
          }
        )
    );
  }

  public onCreateMeasure(event): void {
    event.preventDefault();
    this.persistMeasure();
  }

  public deleteMeasure(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.BIBMEAUSRE, this.selectedMeasureId)
        .subscribe((deletedMeasure) => {
          if (deletedMeasure?.Id) {
            this.measures = this.measures.filter(
              (response) => response.Id != deletedMeasure?.Id
            );
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Delete-Measure-Success'),
              type: ToastType.SUCCESS
            });
          } else {
            this.appService.callNotification({
              message: this.dataService.res('Dt-Notification-Error'),
              type: ToastType.ERROR
            });
          }
        }, error => {
          this.appService.callNotification({
            message: this.dataService.res('Dt-Notification-Error'),
            type: ToastType.ERROR
          });
        })
    );
    this.deleteMeasurePopup.instance.hide();
  }

  public openDeleteMeasureDialog(event): void {
    this.selectedMeasureId = event.row?.data.Id;
    this.deleteMeasurePopup.instance.show();
  }

  public openMeasureDialog(isUpdate?: boolean): void {
    this.isUpdate = isUpdate;
    this.oldValueOfCategory = this.selectedMeasureCategory;
    this.oldValueOfQuestion = this.selectedMeasureQuestion;
    this.oldValueOfTitle = this.measure.Designation;
    this.oldValueOfGoal = this.measure.Goal;
    this.oldValueOfSortNr = this.measure.SortNr;
    this.addMeasurePopup.instance.show();
  }

  public openResponseDetail(event): void {
    this.buttonAction = event.column.name;
    this.measure = event.row?.data;
    this.selectedMeasureCategory = event.row?.data.CategoryId;
    this.selectedMeasureQuestion = event.row?.data.QuestionId;
    this.rowIndex = event.row.dataIndex;
    this.openMeasureDialog(true);
  }

  public onClosePopup(persist?: boolean): void {
    if (!persist) {
      this.fromUngroupedDataQuestion = new DataSource({
        store: new ArrayStore({
          data: this.allQuestions,
          key: 'Id',
        }),
        group: 'Category',
      });
      this.fromUngroupedDataResponse = new DataSource({
        store: new ArrayStore({
          data: this.allResponses,
          key: 'Id',
        }),
        group: 'Question',
      });
      if (this.isUpdate) {
        this.selectedMeasureCategory = this.oldValueOfCategory;
        this.selectedMeasureQuestion = this.oldValueOfQuestion;
        this.measure.Designation = this.oldValueOfTitle;
        this.measure.Goal = this.oldValueOfGoal;
        this.measure.SortNr = this.oldValueOfSortNr;
        this.isUpdate = false;
      }
    }
    this.addMeasurePopup.instance.hide();
    this.deleteMeasurePopup.instance.hide();
    this.emptyData();
  }

  public emptyData(): void {
    this.measure = new BibMeasure(null);
    this.categoryList.instance.reset();
    this.questionsList.instance.reset();
    this.buttonAction = '';
  }

  public returnDxItemCssClass(className: string): string {
    return className;
  }

  public onCellDblClick(event): void {
    if (event.columnIndex > 1)
      this.measuresGrid.editCell(event.rowIndex, event.columnIndex)
  }

  public onRowUpdated(event) {
    const measurePersistQuery: TargetObjectData = new TargetObjectData();
    measurePersistQuery.ObjectKey = new ObjectKey();
    measurePersistQuery.ObjectKey.ObjectType = ObjectKeys.BIBMEAUSRE;

    measurePersistQuery.Mode = PersistMode.Update;
    measurePersistQuery.ObjectKey.Id = event.oldData.Id;

    const measureColumns: TargetColumnValue[] = [
      { Name: 'Title', Value: event.newData.Designation ? event.newData.Designation : event.oldData.Designation },
      { Name: 'Goal', Value: event.newData.Goal ? event.newData.Goal : event.oldData.Goal },
      {
        Name: 'SortNr', Value: event.newData.SortNr ? event.newData.SortNr : event.oldData.SortNr
      },
    ];
    measurePersistQuery.TargetColumns = measureColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = measurePersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((result) => {
      this.appService.callNotification({
        message: this.dataService.res('Dt-Measure-Succees-update'),
        type: ToastType.SUCCESS
      });
    }, () => {
      this.appService.callNotification({ message: this.dataService.res('Dt-Error-Occured'), type: ToastType.ERROR });
    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}